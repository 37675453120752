import classnames from "classnames";
import html2canvas from "html2canvas";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import notificationIcon from "../../files/notification.png";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import CCMintervention from "../CCM/CCMintervention";
import CCMList from "../CCM/CCMList";
import NavigationPrompt from "../Library/NavigationPrompt";
import QuestionnaireList from "../Questionnaire/QuestionnaireList";
import Devices from "../Settings/Devices";
import ProfileDetail from "../Settings/ProfileDetail";
import ViolationList from "../Violations/ViolationList";
import AddConditionsModal from "./AddConditionsModal";
import { BodyTraceBPData } from "./BodyTraceBPData";
import { BodyTraceWSData } from "./BodyTraceWSData";
import { DexcomData } from "./DexcomData";
import { DexcomGlucoseData } from "./DexcomGlucoseData";
import LineGraph from "./LineGraph";
import Notes from "./Notes/";
import TableReading from "./TableReading";
import UpdatePrescribe from "./UpdatePrescribe";
import TableReadingPrint from "./TableReadingPrint";
import GraphTableReading from "./GraphTableReading";
import LottieLoader from "../LottieLoader";
import lottieLoaderFile from "../../files/animation/loading.json";
import summaryIcon from "../../files/report.svg";
import lottieEmptyFile from "../../files/animation/empty_new.json";
import TestingFrequency from "../Settings/TestingFrequency";
import BP from "files/devices/eocene_image_blood_monitor_transparent.png";
import GLUCOSE from "files/devices/eocene_image_glucose_analyzertransparent.png";
import TEMPERATURE from "files/devices/eocene_image_infrared_ear_and_body_termotransparent.png";
import WEIGH from "files/devices/eocene_image_weight_scaletransparent.png";
import PULSE from "files/devices/eocene_pulseoxygenetransparent.png";
import BODYTRACEBP from "files/devices/body_trace_blood_pressure.png";
import BODYTRACEWEIGHT from "files/devices/body_trace_weight_scale.png";
import CHAT_ICON from "files/ic_chat_menu.png";
import NO_IMAGE_DEVICE from "../../files/ic_no_client_images.png";
import IC_EXCEPTION from "../../files/ic_exception.png";
import IC_CCM_PLAN from "../../files/ic_ccm_plan_selection.png";
import IC_CCM_QUESTION from "../../files/ic_ccm_question_selection.png";
import IC_QUESTIONNAIRE from "../../files/ic_questionnaire.png";
import IC_NOTES from "../../files/ic_notes.png";
import ProfileHeader from "../ProfileHeader";
import AddCCMModal from "../CCMForm/AddCCMModal";
import ViewCCMLogs from "../CCMForm/ViewCCMLogs";
import ViewCCMDetails from "../CCMForm/ViewCCMDetails";
import CCMPatientQuestion from "../CCMPatientQuestion/CCMPatientAnswerList.js";
import AddCCMQuestionnaire from "../CCMPatientQuestion/AddCCMQuestionnaire.js";
import CCMPatientAnswerList from "../CCMPatientQuestion/CCMPatientAnswerList.js";
import CCMPatientAnswerListOld from "../CCMPatientQuestion/CCMPatientAnswerListOld.js";
import Reports from "../Reports";
import ThirtyDaysSummary from "../Admin/AdminUserDashboard/ThirtyDaysSummary.js";
import DisplayCardThreshold from "./DisplayCardThreshold.js";
import ConfigerReports from "../Settings/ConfigerReports";
import ChatTextMessage from "../ChatTextMessage/index.jsx";
import socket, {
  connectUser,
  disconnectUser,
  messageReadStatus,
  onReceiveMessage,
  registerUser,
} from "../ChatTextMessage/socket.js";
import ExpectationsList from "./Expectations/ExpectationsList.js";
import MainViolationsComponent from "../Violations/NewViolations/index.js";
class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.stopTimer = this.stopTimer.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      modalAddConditions: false,
      modelAddCCMForm: false,
      modelViewCCMFormDetails: false,
      modelViewCCMFormLogs: false,
      getUserStatistics: "",
      activeTab: "1",
      processSubTypes: "",
      dateRange: 7,
      endDate: moment().format("YYYY-MM-DD"),
      startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      startCustomDate: "",
      endCustomDate: "",
      startDateForPM: moment().startOf("month").format("MMMM DD, YYYY"),
      endDateForPM: moment().endOf("month").format("MMMM DD, YYYY"),
      month: moment().startOf("month").format("MMMM"),
      year: moment().startOf("month").format("YYYY"),
      startDateForExceptions: moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDateForExceptions: moment().add(1, "d").format("YYYY-MM-DD"),
      startDateForNotes: moment().subtract(90, "d").format("YYYY-MM-DD"),
      endDateForNotes: moment().format("YYYY-MM-DD"),
      currentUserId: "",
      openInterventionReport: false,
      interventionData: undefined,
      countException: 0,
      updateConditions: [],
      ccmQuestionsModal: false,
      oldCCMAnswerModal: false,
      ccmAnswersModal: false,
      selectedMonth: "",
      selectedYear: "",
      selectedCCMReport: "",
      loadingCarePlan: true,
      loadingExceptions: true,
      startDateForQuestionnaire: "",
      endDateForQuestionnaire: "",
      questionnaireName: "",
      questionnaireRepeatPer: "",
      endDateError: null,
      startDateError: null,
      graphImage: "",
      csvlink: undefined,
      isIndiviualExpectationLoading: false
    };
    this.toggleAddConditions = this.toggleAddConditions.bind(this);
    this.toggleAddCCMForm = this.toggleAddCCMForm.bind(this);
    this.toggleViewCCMFormDetails = this.toggleViewCCMFormDetails.bind(this);
    this.toggleViewCCMFormLogs = this.toggleViewCCMFormLogs.bind(this);
    this.toggleOldCcmAnswerModal = this.toggleOldCcmAnswerModal.bind(this);
    this.toggleCcmQuestionsModal = this.toggleCcmQuestionsModal.bind(this);
    this.toggleCcmAnswersModal = this.toggleCcmAnswersModal.bind(this);
    this.handleInputChangeUpdateCondition =
      this.handleInputChangeUpdateCondition.bind(this);
    this.updateConditions = this.updateConditions.bind(this);
    this.deleteCondition = this.deleteCondition.bind(this);
    this.updateIndiviualExpectation = this.updateIndiviualExpectation.bind(this);
    this.dateRange = this.dateRange.bind(this);
    this.exportReadingCsv = this.exportReadingCsv.bind(this);
    this.emailReadingReport = this.emailReadingReport.bind(this);
    this.fetchCustomReading = this.fetchCustomReading.bind(this);

    this.getUserStatistics = this.getUserStatistics.bind(this);
    this.processSubTypes = this.processSubTypes.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.openInterventionReport = this.openInterventionReport.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputQuestionnaireChange =
      this.handleInputQuestionnaireChange.bind(this);
    this.handleQuestionnaireChange = this.handleQuestionnaireChange.bind(this);
    this.datesForException = this.datesForException.bind(this);
    this.datesForNotes = this.datesForNotes.bind(this);
    this.countException = this.countException.bind(this);
    this.updatePMSession = this.updatePMSession.bind(this);
    this.handleImageValue = this.handleImageValue.bind(this);
    this.printGraph = React.createRef();
    this.tableExportRef = React.createRef();
    this.isDateValid = this.isDateValid.bind(this);
    this.validatedate = this.validatedate.bind(this);
    this.fullname = this.fullname.bind(this);
  }
  toggleAddConditions() {
    this.setState({
      modalAddConditions: !this.state.modalAddConditions,
    });
  }
  toggleAddCCMForm() {
    this.setState({
      modelAddCCMForm: !this.state.modelAddCCMForm,
    });
  }
  toggleViewCCMFormDetails() {
    this.setState({
      modelViewCCMFormDetails: !this.state.modelViewCCMFormDetails,
    });
  }
  // View submission details for the ccm form
  toggleViewCCMFormLogs() {
    this.setState({
      modelViewCCMFormLogs: !this.state.modelViewCCMFormLogs,
    });
  }
  toggleCcmQuestionsModal() {
    this.setState({
      ccmQuestionsModal: !this.state.ccmQuestionsModal,
    });
  }
  toggleOldCcmAnswerModal() {
    this.setState({
      oldCCMAnswerModal: !this.state.oldCCMAnswerModal,
    });
  }
  toggleCcmAnswersModal() {
    this.setState({
      ccmAnswersModal: !this.state.ccmAnswersModal,
    });
  }
  handleInputChangeUpdateCondition(e) {
    const conditions = _.map(e, "_id");
    this.setState({ updateConditions: conditions });
  }

  updateIndiviualExpectation(data) {
    this.props.dispatch({
      type: "DO_SAVE_PATIENT_EXPECTATION_RULE",
      payload: data
    });
    this.props.dispatch({
      type: "DO_GET_PATIENT_CONDITIONS",
      payload: { userid: this.state.currentUserId },
    });
    this.props.dispatch({
      type: "DO_GET_PATIENT_CONDITIONS",
      payload: { userid: this.state.currentUserId },
    });
  }

  updateConditions(e) {
    e.preventDefault();
    this.props.dispatch({
      type: "DO_ADD_CONDITIONS",
      payload: {
        userId: this.props.profile._id,
        conditions: this.state.updateConditions,
      },
    });
    // this.props.dispatch({
    //   type: "DO_GET_PATIENT_CONDITIONS",
    //   payload: { userid: this.props.profile._id }
    // });
    window.location.reload(false);
    // this.toggleAddConditions();
  }
  deleteCondition(conditionId) {
    this.props.dispatch({
      type: "DO_DELETE_CONDITION",
      payload: {
        userid: this.props.profile._id,
        conditionid: conditionId,
      },
    });
    window.location.reload(false);
  }
  datesForException(value) {
    this.setState({
      startDateForExceptions: value.startDate,
      endDateForExceptions: value.endDate,
    });
    this.props.dispatch({
      type: this.props.owner
        ? "DO_GET_MY_VIOLATIONS"
        : "DO_GET_USER_VIOLATIONS",
      payload: { ...value, patientId: this.props.profile._id },
    });
  }
  datesForNotes(value) {
    this.setState(value);
  }
  countException(value) {
    this.setState({ countException: value });
  }
  exportReadingCsv(
    readingType,
    is_body_trace_bp_data,
    is_body_trace_ws_data,
    isBloodPressureReadingUploded
  ) {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.props.dispatch({
      type: "DO_REMOVE_PATIENT_LINK_PATH",
      payload: undefined,
    });

    this.props.dispatch({
      type: "DO_EXPORT_READING_RECORDS",
      payload: {
        id: id,
        startDate: this.state.endDate
          ? moment.utc(this.state.startDate).startOf("day").format()
          : moment.utc().startOf("day").format(),
        endDate: this.state.endDate
          ? moment.utc(this.state.endDate).endOf("day").format()
          : moment.utc().endOf("day").format(),
        readingType: readingType,
        is_body_trace_ws_data: is_body_trace_ws_data || false,
        is_body_trace_bp_data: is_body_trace_bp_data || false,
        timezone: moment.tz.guess(),
        isBloodPressureReading: isBloodPressureReadingUploded,
      },
    });
  }

  /**
   * Email Reading Report!
   * @param {*} readingType Selected Reading Type!
   * @param {*} email Entered Email!
   */
  emailReadingReport(
    readingType,
    is_body_trace_bp_data,
    is_body_trace_ws_data,
    email,
    isBloodPressureReadingUploded
  ) {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.props.dispatch({
      type: "DO_READING_REPROT_EMAIL",
      payload: {
        patient: id,
        isEmail: true,
        isWeb: true,
        isBodytraceBP: is_body_trace_bp_data,
        isBodytraceWS: is_body_trace_ws_data,
        startDate: this.state.endDate
          ? moment.utc(this.state.startDate).startOf("day").format()
          : moment.utc().startOf("day").format(),
        endDate: this.state.endDate
          ? moment.utc(this.state.endDate).endOf("day").format()
          : moment.utc().endOf("day").format(),
        readingTypeID: readingType
          .replace("633c2614ebfa2e7e9022fab2", "5bb306382598931ffbd1b624")
          .replace("633c2628ebfa2e7e9022fab3", "5bb306382598931ffbd1b625"),
        timezone: moment.tz.guess(),
        email: email,
        isBloodPressureReading: isBloodPressureReadingUploded,
      },
    });
    Swal.fire({
      title: "Reading Report Sent!",
      text: "The reading report has been successfully sent to the entered email address!",
      icon: "success",
    });
  }
  updatePMSession() {
    const combineMonthYear = this.state.month + " " + this.state.year;

    this.props.dispatch({
      type: "DO_GET_CCM_RECORDS",
      payload: {
        user: this.state.currentUserId,
        start: moment(combineMonthYear, "MMMM YYYY").startOf("month").format(),
        end: moment(combineMonthYear, "MMMM YYYY").endOf("month").format(),
        timezone: moment.tz.guess(),
      },
    });
  }
  handleImageValue(event) {
    const target = event.target;
    this.setState({ graphImage: target });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        const combineMonthYear = this.state.month + " " + this.state.year;
        this.setState({
          startDateForPM: moment(combineMonthYear, "MMMM YYYY")
            .startOf("month")
            .format(),
          endDateForPM: moment(combineMonthYear, "MMMM YYYY")
            .endOf("month")
            .format(),
        });
        this.props.dispatch({
          type: "DO_GET_CCM_RECORDS",
          payload: {
            user: this.state.currentUserId,
            start: moment(combineMonthYear, "MMMM YYYY")
              .startOf("month")
              .format(),
            end: moment(combineMonthYear, "MMMM YYYY").endOf("month").format(),
            timezone: moment.tz.guess(),
          },
        });
      }
    );
  }

  /**
   * Questionnaire date inputs
   * @param {*} event
   */
  handleInputQuestionnaireChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.setState({ [name]: value }, () => {
      this.isEndDateValid(this.state.endDateForQuestionnaire);
      if (
        this.props.userRole != "patient" &&
        this.state.startDateForQuestionnaire &&
        this.state.endDateForQuestionnaire &&
        this.state.endDateError == null
      ) {
        this.props.dispatch({
          type: "DO_GET_MY_QUESTIONNAIRES",
          payload: {
            patientId: id,
            start: this.state.startDateForQuestionnaire,
            end: this.state.endDateForQuestionnaire,
            name: this.state.questionnaireName,
            repeatPer: this.state.questionnaireRepeatPer,
          },
        });
      }
    });
  }
  /**
   * Validation of the End Date field
   * @param {*} value Entered value
   * @returns validation status
   */
  isEndDateValid(value) {
    if (value === undefined || value === null || value.trim().length == 0) {
      // this.setState({
      //   endDateError :"End date must be filled out!"
      // });
      return false;
    } else {
      var date = moment(
        new Date(this.state.startDateForQuestionnaire),
        "MM/DD/YYYY"
      );
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      var dateEntered = new Date(value);
      var today = moment(date, "MM/DD/YYYY");
      var enteredDate = moment(dateEntered, "MM/DD/YYYY");
      var dDiff = today.isSameOrAfter(enteredDate);
      if (dDiff) {
        this.setState({
          endDateError:
            "Please select end date is greater than equal to start date.",
        });
        return false;
      } else if (today.isSame(enteredDate)) {
        this.setState({
          endDateError: null,
        });
        return true;
      } else {
        this.setState({
          endDateError: null,
        });
        return true;
      }
    }
  }

  goBack() {
    this.props.history.goBack();
  }
  openInterventionReport(userId) {
    this.setState({
      openInterventionReport: !this.state.openInterventionReport,
      interventionData: userId,
    });
  }
  toggleModal(i) {
    const modalIndex = "gwapo" + i;
    this.setState({ ["gwapo" + i]: !this.state[modalIndex] });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.props.dispatch({
        type: "DO_GET_UNREAD_COUNT",
      });
      this.setState({
        activeTab: tab,
      });
    }
  }
  fullname(cell, row) {
    if (!row.device) {
      return <div>Wrong Device</div>;
    }
    if (row.device.brand.indexOf("Body Trace Blood") > -1)
      return (
        <div className="text-center">
          <img
            height="200px"
            src={BODYTRACEBP}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.brand.indexOf("Body Trace Weight") > -1)
      return (
        <div className="text-center">
          <img
            height="200px"
            src={BODYTRACEWEIGHT}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Blood") > -1)
      return (
        <div className="text-center">
          <img
            height="200px"
            src={BP}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Glucose") > -1)
      return (
        <div className="text-center">
          <img
            src={GLUCOSE}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Thermometer") > -1)
      return (
        <div className="text-center">
          <img
            src={TEMPERATURE}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Weigh") > -1)
      return (
        <div className="text-center">
          <img
            src={WEIGH}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Pulse") > -1)
      return (
        <div className="text-center">
          <img
            src={PULSE}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    return (
      <div className="text-center">
        <img
          src={NO_IMAGE_DEVICE}
          className="rounded-circle img-fluid reading-icon-summary"
        />
      </div>
    );
  }
  getUserStatistics() {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    const finalId = id ? id : this.props.userId;
    const userAccess = this.props.usersOfferedAccess;
    const userStat = _.filter(userAccess, {
      receivedFrom: { _id: id },
    });
    this.setState({
      getUserStatistics: userStat,
      userProfile: userStat[0].receivedFrom,
    });
    return userStat[0];
  }
  processSubTypes() {
    const readingSubTypes = this.props.readingSubTypes;
    const userStat = this.getUserStatistics();
    const newTypes = readingSubTypes.map((value, i) => {
      const readings = _.filter(userStat.readingsSevenDays, {
        readingType: value.reading,
      });
      if (readings.length > 0) {
        return value;
      }
    });
    const removeUndefined = _.reject(newTypes, _.isNil);

    this.setState({
      processSubTypes: removeUndefined,
    });
  }
  groupByUser() {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    const dateFrom = moment(this.state.startDateForPM).format("MMMM DD, YYYY");
    const dateTo = moment(this.state.endDateForPM)
      .endOf("day")
      .format("MMMM DD, YYYY");
    const addOneDay = moment(this.state.endDateForPM)
      .add(1, "day")
      .format("MMMM DD, YYYY");
    var result = _.chain(this.props.pmRecords)
      .groupBy("patient._id")
      .map((timeList, userId) => {
        if (timeList[0].patient) {
          const trackTime = _.filter(timeList, function (value) {
            return moment(value.startTime).isBetween(dateFrom, addOneDay);
          });
          const fullname =
            timeList[0].patient.fname + " " + timeList[0].patient.lname;
          const readingCount = timeList[0].readingCount;
          const userObject = timeList[0].patient;
          const nonAdhenrenceDay = timeList[0].nonAdhenrenceDay;
          return {
            trackTime,
            userId,
            fullname,
            dateFrom,
            dateTo,
            userObject,
            nonAdhenrenceDay,
            readingCount,
          };
        }
      })
      .value();

    const filterResult = _.filter(result, {
      userId: id,
    });
    return filterResult;
  }
  dynamicColor() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);

    return "rgb(" + r + "," + g + "," + b + ")";
  }
  componentWillUnmount() {
    if (this.state.currentUserId) {
      disconnectUser(this.state.currentUserId);
    }
    // Clean up socket event listeners and disconnect
    if (socket) {
      socket.off("receiveMessage", this.handleReceiveMessage);
    }
    this.props.dispatch({
      type: "DO_REMOVE_PROFILE_DATA",
    });
    this.props.dispatch({
      type: "START_TIMER",
      payload: false,
    });
  }
  toCapitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  // Handle the received message and update the state
  handleReceiveMessage = (data) => {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT",
    });
    if (data.recepient._id != "5dbf6633557aab35df52bc31") {
      if (this.props.owner && this.state.activeTab == 12) {
        messageReadStatus(data, this.props.owner);
      } else if (
        this.props.profileOwner &&
        this.props.profileOwner._id &&
        this.state.activeTab == 12
      ) {
        messageReadStatus(data, this.props.profileOwner._id);
      }
    }
    const objectExists = _.some(this.props.allChatMessages, { _id: data._id });
    if (!objectExists) {
      this.props.dispatch({
        type: "DO_CHAT_MESSAGE",
        payload: data,
      });
    } else {
      console.log("RECEIVED MESSAGES IS EXIST IN ARRAY");
    }
  };
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT",
    });
    let id;
    if (this.props.owner) {
      id = this.props.owner;
      if (id) {
        connectUser();
        registerUser(id);
        onReceiveMessage(this.handleReceiveMessage);
      }
    } else {
      id = this.props.match.params.id;
      if (this.props.profileOwner && this.props.profileOwner._id) {
        connectUser();
        registerUser(this.props.profileOwner._id);
        onReceiveMessage(this.handleReceiveMessage);
      }
      const { start, end } = this.props.match.params;
      if (start) {
        this.setState(
          {
            dateRange: "custom",
            startDate: moment.utc(start).startOf("day").format(),
            endDate: moment.utc(end).endOf("day").format(),
          },
          () => {
            this.props.dispatch({
              type: "DO_GET_USER_READINGS",
              payload: {
                user: id,
                startDate: moment(start).startOf("day").utc().format(),
                endDate: moment(end).endOf("day").utc().format(),
              },
            });
            this.props.dispatch({
              type: "DO_GET_USERS_DEXCOM_GLUCOSE_DATA",
              payload: {
                user: id,
                startDate: moment(start).format(),
                endDate: moment(end).format(),
              },
            });
          }
        );
      } else {
        this.props.dispatch({
          type: "DO_GET_USER_READINGS",
          payload: {
            user: id,
            startDate: moment(this.state.startDate)
              .startOf("day")
              .utc()
              .format(),
            endDate: moment(this.state.endDate).endOf("day").utc().format(),
          },
        });
        this.props.dispatch({
          type: "DO_GET_USERS_DEXCOM_GLUCOSE_DATA",
          payload: {
            user: id,
            startDate: moment(this.state.startDate).format(),
            endDate: moment(this.state.endDate).format(),
          },
        });
      }

      this.setState({ currentUserId: id });
    }

    if (this.props.owner) {
      this.props.dispatch({
        type: "DO_GET_PROFILE",
      });

      this.props.dispatch({
        type: "DO_GET_USER_READINGS",
        payload: {
          user: id,
          startDate: moment(this.state.startDate).startOf("day").utc().format(),
          endDate: moment(this.state.endDate).endOf("day").utc().format(),
          patientPath: true,
        },
      });
      this.props.dispatch({
        type: "DO_GET_USERS_DEXCOM_GLUCOSE_DATA",
        payload: {
          user: id,
          startDate: moment(this.state.startDate).format(),
          endDate: moment(this.state.endDate).format(),
          patientPath: true,
        },
      });
      this.props.dispatch({
        type: "DO_GET_MY_VIOLATIONS",
        payload: {
          startDate: moment(this.state.startDateForExceptions)
            .startOf("day")
            .utc()
            .format(),
          endDate: moment(this.state.endDateForExceptions)
            .endOf("day")
            .utc()
            .format(),
        },
      });
      // this.props.dispatch({
      //   type: "DO_GET_CCM_RECORDS"
      // });
      // this.props.dispatch({
      //   type: "DO_GET_USER_READINGS",
      //   payload: { user: id, patientPath: true }
      // });
    } else {
      this.props.dispatch({
        type: "DO_GET_PROFILE",
        payload: { authProfile: true },
      });
      this.props.dispatch({
        type: "DO_GET_USER_VIOLATIONS",
        payload: {
          startDate: moment(this.state.startDateForExceptions)
            .startOf("day")
            .utc()
            .format(),
          endDate: moment(this.state.endDateForExceptions)
            .endOf("day")
            .utc()
            .format(),
          patientId: id,
        },
      });
      this.props.dispatch({
        type: "DO_GET_PROFILE_PATIENT",
        payload: { user: id }
      });
      /*
      Unneccesary API call. This is is a PM- sessions API.
      */
      // this.props.dispatch({
      //   type: "DO_GET_CCM_RECORDS",
      //   payload: {
      //     user: this.state.currentUserId,
      //     start: this.state.startDateForPM,
      //     end: this.state.endDateForPM,
      //     timezone: moment.tz.guess()
      //   }
      // });
      this.props.dispatch({
        type: "DO_GET_USER_NOTES",
        payload: { userid: id },
      });
      this.props.dispatch({
        type: "DO_GET_USER_PMSESSION_TIME",
        payload: { userid: id, timeZone: moment.tz.guess() },
      });
    }
    if (this.props.userRole != undefined && this.props.userRole != "patient") {
      this.props.dispatch({
        type: "DO_GET_PATIENT_CCM_ANSWERS",
        payload: { userid: id },
      });
      this.props.dispatch({
        type: "DO_GET_MY_QUESTIONNAIRES",
        payload: { patientId: id },
      });
      this.props.dispatch({
        type: "DO_GET_PATIENT_FREQUENCY",
        payload: {
          user: id,
        },
      });
      this.props.dispatch({
        type: "DO_GET_MY_QUESTIONNAIRES_NAME_REPETED",
        payload: { patientId: id },
      });
      this.props.dispatch({
        type: "DO_GET_PATIENT_CONDITIONS",
        payload: { userid: id },
      });
      this.props.dispatch({
        type: "DO_GET_PATIENT_CCM_SUBMISSION",
        payload: { userid: id },
      });
    }
    this.props.dispatch({
      type: "DO_GET_DEVICES",
      payload: {
        id: id,
      },
    });
    // Fetching care-plan expectation for patient
    this.props.dispatch({
      type: "DO_GET_EXPECTATION_CAREPLAN_PATIENT",
      payload: { user: id },
    });
    this.props.dispatch({
      type: "DO_GET_USER_VIOLATIONS_RECORD_COUNT",
      payload: {
        patientId: id,
        startDate : moment().subtract(7, "d").format("YYYY-MM-DD"),
        endDate : moment().endOf("day").utc().format()
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.patientConditions !== prevProps.patientConditions &&
      this.props.patientExpectations !== prevProps.patientExpectations
    ) {
      this.setState({ loadingCarePlan: false });
    }

    if (this.props.usersViolations !== prevProps.usersViolations) {
      this.setState({ loadingExceptions: false });
    }

    if (prevProps.patientExpectations !== this.props.patientExpectations || this.props.patientConditions !== prevProps.patientConditions) {
      console.log("patientExpectations have updated");
    }
  }

  stopTimer(onConfirm) {
    this.props.dispatch({
      type: "START_TIMER",
      payload: false,
    });
    onConfirm();
  }
  fetchCustomReading() {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.props.dispatch({
      type: "DO_GET_USER_READINGS",
      payload: {
        user: id,
        startDate: moment(this.state.startDate).startOf("day").utc().format(),
        endDate: moment(this.state.endDate).endOf("day").utc().format(),
        patientPath: this.props.owner ? true : false,
      },
    });
    if (this.props.userRole != "patient") {
      this.props.dispatch({
        type: "DO_GET_MY_QUESTIONNAIRES",
        payload: { patientId: this.props.profile._id },
      });
      this.props.dispatch({
        type: "DO_GET_MY_QUESTIONNAIRES_NAME_REPETED",
        payload: { patientId: this.props.profile._id },
      });
    }
    this.props.dispatch({
      type: "DO_GET_USERS_DEXCOM_GLUCOSE_DATA",
      payload: {
        user: id,
        startDate: moment(this.state.startDate).format(),
        endDate: moment(this.state.endDate).format(),
        patientPath: this.props.owner ? true : false,
      },
    });
  }
  validatedate(date, name) {
    const dateParts = date.split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);

    if (name == "startDate") {
      this.setState({
        startDateError: null,
      });
    } else {
      this.setState({
        endDateError: null,
      });
    }

    if (name == "endDate" && _.isEmpty(this.state.startCustomDate)) {
      this.setState({ startDateError: "Please select first start date." });
      return false;
    }

    // JavaScript Date object handles invalid dates by returning "Invalid Date"
    if (year.toString().length != 4) {
      if (name == "startDate") {
        this.setState({
          startDateError: "Please select proper formmated start date.",
        });
      } else {
        this.setState({
          endDateError: "Please select proper formmated end date.",
        });
      }
    } else {
      const todayDate = moment().add(1, "d").format("YYYY-MM-DD");
      // Check date is greter than today or not
      if (date > todayDate) {
        if (name == "startDate") {
          this.setState({
            startDateError: "Please select start date less than today.",
          });
        } else {
          this.setState({
            endDateError: "Please select end date less than today.",
          });
        }
      } else {
        const isValid = !isNaN(year) && !isNaN(month) && !isNaN(day);
        const checkDate = this.isDateValid(date, name);
        if (isValid && year.toString().length == 4 && checkDate) {
          return true;
        }
      }
    }
    return false;
  }
  dateRange(event) {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let dateTo;
    dateTo = moment().format();
    if (name == "startDate") {
      this.setState({ startCustomDate: value });
    } else if (name == "endDate") {
      this.setState({ endCustomDate: value });
    }

    let dateFrom;
    if (name === "dateRange") {
      this.setState({
        startDate: "",
        endDate: "",
        startCustomDate: "",
        endCustomDate: "",
      });
      if (value == 7) {
        dateFrom = moment().subtract(7, "d").format();
      }
      if (value == 1) {
        dateFrom = moment().subtract(1, "M").format();
      }
      if (value == 6) {
        dateFrom = moment().subtract(6, "M").format();
      }

      if (value == "custom") {
        this.setState({ [name]: value }, () => {});
      } else {
        this.setState(
          { startDate: dateFrom, endDate: dateTo, [name]: value },
          () => {
            const checkStartDate = this.validatedate(
              this.state.startDate,
              "startDate"
            );
            if (value !== "custom" && checkStartDate) {
              this.props.dispatch({
                type: "DO_GET_USER_READINGS",
                payload: {
                  user: id,
                  startDate: moment(dateFrom).startOf("day").utc().format(),
                  endDate: moment(dateTo).endOf("day").utc().format(),
                  patientPath: this.props.owner ? true : false,
                },
              });
            }
          }
        );
      }
    } else {
      this.setState({ [name]: value }, () => {
        const { endDate, startDate, dateRange } = this.state;
        const endFormateDate = _.isEmpty(endDate)
          ? moment().format("YYYY-MM-DD")
          : this.state.endDate;
        const checkStartDate = this.validatedate(
          this.state.startDate,
          "startDate"
        );
        const checkEndDate = this.validatedate(endFormateDate, "endDate");
        if (dateRange == "custom") {
          if (startDate && endFormateDate && checkStartDate && checkEndDate) {
            this.props.dispatch({
              type: "DO_GET_USER_READINGS",
              payload: {
                user: id,
                startDate: moment(this.state.startDate)
                  .startOf("day")
                  .utc()
                  .format(),
                endDate: moment(endFormateDate).endOf("day").utc().format(),
                patientPath: this.props.owner ? true : false,
              },
            });
          }
        }
      });
    }
  }

  componentWillUnmount() {
    if (!this.props.owner) {
      this.props.dispatch({
        type: "DO_REMOVE_PROFILE_DATA",
      });
    }

    this.props.dispatch({
      type: "START_TIMER",
      payload: false,
    });
  }

  handleQuestionnaireChange = (event) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    if (value == -1) {
      value = "";
    }
    const name = target.name;
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.setState({ [name]: value }, () => {
      const {
        startDateForQuestionnaire,
        endDateForQuestionnaire,
        questionnaireName,
        questionnaireRepeatPer,
        endDateError,
      } = this.state;
      this.setState({ [name]: value }, () => {
        if (
          this.props.userRole != "patient" &&
          this.state.startDateForQuestionnaire &&
          this.state.endDateForQuestionnaire &&
          endDateError == null
        ) {
          this.props.dispatch({
            type: "DO_GET_MY_QUESTIONNAIRES",
            payload: {
              patientId: id,
              start: startDateForQuestionnaire,
              end: endDateForQuestionnaire,
              name: questionnaireName,
              repeatPer: questionnaireRepeatPer,
            },
          });
        } else {
          this.props.dispatch({
            type: "DO_GET_MY_QUESTIONNAIRES",
            payload: {
              patientId: id,
              start: startDateForQuestionnaire,
              end: endDateForQuestionnaire,
              name: questionnaireName,
              repeatPer: questionnaireRepeatPer,
            },
          });
        }
      });
    });
  };
  /*
   * Validation of Both date field
   * @params value
   */
  isDateValid(value, name) {
    if (value === undefined || value === null || value.trim().length == 0) {
      return true;
    } else {
      let date;
      const startDate = moment(this.state.startCustomDate).format("YYYY-MM-DD");
      const todayDate = moment().add(1, "d").format("YYYY-MM-DD");
      if (
        name == "endDate" &&
        startDate < todayDate &&
        !_.isEmpty(this.state.startCustomDate)
      ) {
        date = moment(new Date(this.state.startCustomDate), "MM/DD/YYYY");
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        var dateEntered = new Date(value);
        var today = moment(date, "MM/DD/YYYY");
        var enteredDate = moment(dateEntered, "MM/DD/YYYY");
        var dDiff = today.isSameOrAfter(enteredDate);
        if (dDiff) {
          this.setState({
            endDateError:
              "Please select end date is greater than equal to start date.",
          });
          return false;
        } else if (today.isSame(enteredDate)) {
          this.setState({
            endDateError: null,
          });
          return true;
        } else {
          this.setState({
            endDateError: null,
          });
          return true;
        }
      }
    }
    return true;
  }
  render() {
    if (this.props.readingFilePath) {
      if (this.state.csvlink === undefined) {
        this.setState(
          {
            csvlink: this.props.readingFilePath,
          },
          () => {
            this.props.dispatch({
              type: "DO_REMOVE_PATIENT_LINK_PATH",
              payload: undefined,
            });
            window.open(this.state.csvlink, "_self");
          }
        );
      }
    } else {
      this.state.csvlink = undefined;
    }

    let currentUser;
    if (this.props.owner) {
      currentUser = this.props.owner;
    } else {
      currentUser = this.props.match.params.id;
    }
    const expectationRule = this.props.patientCarePlanExpectations;
    const expectationWeightRule = this.props.patientWeightExpectations;
    const bodytraceReadings = this.props.bodytraceReadings;
    const myQuestionnaire = this.props.myQuestionnaire;
    const questionnaireNameList = this.props.questionnaireName;
    const questionnaireRepetedList = this.props.questionnaireRepeted;
    const pmFiltered = this.groupByUser();
    const properStartDate = moment(this.state.startDateForNotes)
      .startOf("day")
      .format();
    const properEndDate = moment(this.state.endDateForNotes)
      .endOf("day")
      .format();
    const filteredNotes = this.props.notes ? this.props.notes : 0
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const startYear = moment("September 2018").startOf("month").format("YYYY");
    const endYear = moment().startOf("month").format("YYYY");
    const startMonth = moment("September 2018").startOf("month").format("YYYY");
    const endMonth = moment().startOf("month").format("YYYY");
    var years = moment(endYear).diff(startYear, "years");
    var yearList = [];
    for (var i = 2018; i <= endYear; i++) yearList.push(i);
    // Define custom sorting function
    const sortedArrayViewExpectations = this.props.patientExpectations
      ? _.sortBy(this.props.patientExpectations, (obj) => {
          var name = obj.readingSubTypeId;
          var vitalsObject;
          if (this.props.patientExpectations == "Trending Violation") {
            vitalsObject = _.find(
              this.props.patientExpectations,
              (v) => v.ruleType == "Vitals" && obj.rule[0].ruleId == v._id
            );
            if (vitalsObject) {
              name = vitalsObject.readingSubTypeId;
            }
          }
          if (!name) {
            return 18;
          }
          if (name.includes("5be56b848c5d7a064721798f")) return 1;
          if (name.includes("5be56beb3863cd0647d79890")) return 2;
          if (name.includes("5be56c1a3863cd0647d79891")) return 3;
          if (name.includes("5be56c293863cd0647d79892")) return 4;
          if (name.includes("5be56c383863cd0647d79893")) return 5;
          if (name.includes("5be56c493863cd0647d79894")) return 6;
          if (name.includes("5be56cad3863cd0647d79895")) return 7;
          if (name.includes("5be56cbe3863cd0647d79896")) return 8;
          if (name.includes("5be56cdf3863cd0647d79897")) return 9;
          if (name.includes("5be56d0b3863cd0647d79898")) return 10;
          if (name.includes("5be56d233863cd0647d79899")) return 11;
          if (name.includes("5be56d4c3863cd0647d7989a")) return 12;
          if (name.includes("5be56d593863cd0647d7989b")) return 13;
          if (name.includes("5be56d623863cd0647d7989c")) return 14;
          if (name.includes("5facda01d3c27132448a0ba5")) return 15;
          if (name.includes("5facda06d3c27132448a0ba6")) return 16;
          if (name.includes("63343d03db4e75cc4566c516")) return 17;
          return 18; // For items without V, T, or W
        })
      : [];

      
    if (this.props.profile && this.props.profileOwner) {
      const { devices = [] } = this.props.profile;

      const mapDevice = _.map(devices, "device");
      const readingTypesUser = _.map(mapDevice, "readingType");
      const getUniqReadingTypes = _.uniq(readingTypesUser);
      const userReadingTypesAvailable = getUniqReadingTypes.filter(Boolean);

      const defaultUnits = {
        umBloodPressure: "mmHg",
        umGlucose: "mg/dL",
        umHeight: "ft",
        umTemperature: "F",
        umWeight: "lb",
      };
      const unitMeasurement = this.props.profileOwner
        ? this.props.profileOwner.unitMeasurement
        : defaultUnits;

      const row = this.props.profile;
      const readingSubType = this.props.readingSubTypes.filter(
        (item) => item.description !== "Weight Difference"
      );

      return (
        <div>
          <NavigationPrompt when={this.props.timerStarts}>
            {({ isActive, onConfirm, onCancel }) => (
              <SweetAlert
                warning
                title="You are leaving this page!"
                showCancel
                confirmBtnText="Leave!"
                cancelBtnText="Stay"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="info"
                show={isActive}
                onCancel={onCancel}
                onConfirm={() => {
                  this.stopTimer(onConfirm);
                }}
              >
                Click yes to save the tracked time and leave this page
              </SweetAlert>
            )}
          </NavigationPrompt>

          <div className="`row mb-5`">
            <div className="col-12 remove-table-padding">
              <Alert color="success">
                Each section are results based on a date range (dates are
                indicated below the menu icon) Readings and Exceptions
                (default is 7 days before To current date) while pm sessions is
                current month . The counter number will also change when you
                change the date range for that particular section
              </Alert>
              <Row className="col-12 header-device-list remove-table-padding">
                <Col sm="11" className="center-label remove-table-padding">
                  {this.props.profile && (
                    <ProfileHeader
                      profile={this.props.profile}
                      latestReadingUser={this.props.latestReadingUser}
                      ccmTotalDuration={this.props.ccmTotalDuration}
                      rpmTotalDuration={this.props.rpmTotalDuration}
                    />
                  )}
                </Col>
                <Col
                  sm="1"
                  className="center-label remove-table-padding justify-content-end "
                >
                  {!this.props.hideBackButton && (
                    <span
                      className={`align-items-center mb-3 remove-table-padding`}
                    >
                      <button
                        className="text-white mr-auto btn btn-gradient-dark btn-icon-text"
                        onClick={this.goBack}
                      >
                        <i className="mdi mdi-arrow-left btn-icon-prepend" />
                        Back
                      </button>
                    </span>
                  )}
                </Col>
              </Row>
            </div>
            <div className="col-12 grid-margin remove-table-padding">
              <section>
                <div
                  className="sttabs tabs-style-iconbox"
                  style={{ overflow: "visible" }}
                >
                  <nav>
                    <ul>
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggle("1");
                          this.props.dispatch({
                            type: "DO_GET_USER_READINGS",
                            payload: {
                              user: currentUser,
                              startDate: moment(this.state.startDate)
                                .startOf("day")
                                .utc()
                                .format(),
                              endDate: moment(this.state.endDate)
                                .endOf("day")
                                .utc()
                                .format(),
                            },
                          });
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <img
                            src="https://cdn2.iconfinder.com/data/icons/business-flat-icons-set/142/04-512.png"
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> READINGS AND GRAPH
                          </span>
                          <br />
                          <small>
                            (
                            {moment(
                              _.isEmpty(this.state.startDate)
                                ? moment().subtract(7, "d").format("YYYY-MM-DD")
                                : this.state.startDate
                            ).format("MMM DD, YYYY")}{" "}
                            to{" "}
                            {moment(
                              _.isEmpty(this.state.endDate)
                                ? moment().format()
                                : this.state.endDate
                            ).format("MMM DD, YYYY")}
                            )
                          </small>
                        </a>
                      </li>
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "9",
                        })}
                        onClick={() => {
                          this.toggle("9");
                          this.props.dispatch({
                            type: "DO_GET_THIRTY_DAYS_SUMMARY",
                            payload: { user: currentUser },
                          });
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <img
                            src={summaryIcon}
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> 30 DAY SUMMARY
                          </span>
                          <br />
                        </a>
                      </li>
                      {!this.props.hideException && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2");
                            this.props.dispatch({
                              type: "DO_GET_USER_VIOLATIONS",
                              payload: {
                                startDate: moment(
                                  this.state.startDateForExceptions
                                )
                                  .startOf("day")
                                  .utc()
                                  .format(),
                                endDate: moment(this.state.endDateForExceptions)
                                  .endOf("day")
                                  .utc()
                                  .format(),
                                patientId: currentUser,
                              },
                            });
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            <span className="notify-badge">
                            {this.props.usersViolationsRecordsCount && this.props.usersViolationsRecordsCount.totalRecordCount ? this.props.usersViolationsRecordsCount.totalRecordCount : 0}
                            </span>{" "}
                            <img
                              src={IC_EXCEPTION}
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              <br /> EXCEPTIONS
                            </span>
                            <br />
                          </a>
                        </li>
                      )}

                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "4",
                        })}
                        onClick={() => {
                          this.toggle("4");
                          this.props.dispatch({
                            type: "DO_GET_USER_NOTES",
                            payload: { userid: currentUser },
                          });
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <span className="notify-badge">
                            {_.size(filteredNotes)}
                          </span>{" "}
                          <img
                            src={IC_NOTES}
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> NOTES
                          </span>
                          <br />
                        </a>
                      </li>
                      {this.props.patientConditions &&
                        this.props.userRole != "patient" && (this.props.isOtherRole == false && this.props.staffRole != 'Shared/Family Account') && (
                          <li
                            className={classnames({
                              tabcurrent: this.state.activeTab === "8",
                            })}
                            onClick={() => {
                              this.toggle("8");
                              this.props.dispatch({
                                type: "DO_GET_PATIENT_CCM_ANSWERS",
                                payload: { userid: currentUser },
                              });
                            }}
                          >
                            <a className="char font-bold urgent-tabs">
                            <img
                              src={IC_CCM_QUESTION}
                              width="80px"
                              className="img-circle"
                              alt
                            />
                              <br />
                              <span>
                                {" "}
                                <br /> CCM
                              </span>
                              <br />
                            </a>
                          </li>
                        )}
                      {this.props.patientConditions &&
                        this.props.userRole != "patient" && (
                          <li
                            className={classnames({
                              tabcurrent: this.state.activeTab === "10",
                            })}
                            onClick={() => {
                              this.toggle("10");
                              this.props.dispatch({
                                type: "DO_GET_MY_QUESTIONNAIRES",
                                payload: { patientId: currentUser },
                              });
                            }}
                          >
                            <a className="char font-bold urgent-tabs">
                              <img
                                src={IC_QUESTIONNAIRE}
                                width="80px"
                                className="img-circle"
                                alt
                              />
                              <br />
                              <span>
                                {" "}
                                <br /> QUESTIONNAIRES
                              </span>
                              <br />
                            </a>
                          </li>
                        )}
                      {this.props.patientConditions &&
                        this.props.userRole != "patient" && (
                          <li
                            className={classnames({
                              tabcurrent: this.state.activeTab === "7",
                            })}
                            onClick={() => {
                              this.toggle("7");
                              this.props.dispatch({
                                type: "DO_GET_PATIENT_CONDITIONS",
                                payload: { userid: currentUser },
                              });
                            }}
                          >
                            <a className="char font-bold urgent-tabs">
                              <img
                                src={IC_CCM_PLAN}
                                width="80px"
                                className="img-circle"
                                alt
                              />
                              <br />
                              <span>
                                {" "}
                                <br /> CARE PLAN
                              </span>
                              <br />
                            </a>
                          </li>
                        )}
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "11",
                        })}
                        onClick={() => {
                          this.toggle("11");
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <img
                            src={notificationIcon}
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br />
                            NOTIFICATION & MESSAGES
                          </span>
                          <br />
                        </a>
                      </li>
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "12",
                        })}
                        onClick={() => {
                          this.toggle("12");
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <img
                            src={CHAT_ICON}
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> MESSAGE CHAT
                          </span>
                          <br />
                        </a>
                      </li>
                      {!this.props.hideProfile && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5");
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            <img
                              src="https://cdn0.iconfinder.com/data/icons/education-flat-icons-set/128/student-identity-badge-512.png"
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              {" "}
                              <br /> PROFILE & DEVICES
                            </span>
                            <br />
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                  <div className="content-wrap">
                    <section
                      id="section-iconbox-1"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "1",
                      })}
                    >
                      {this.props.ongoingPatientProfileRequest && (
                        <LottieLoader
                          loadLoop={true}
                          autoplayLoop={true}
                          source={lottieLoaderFile}
                          Lheight={150}
                          Lwidth={150}
                          subTitle={"Please Wait ..."}
                        />
                      )}
                      {!this.props.ongoingPatientProfileRequest && (
                        <>
                          <div className="text-center">Date Range</div>
                          <div className="text-center">
                            <select
                              name="dateRange"
                              className="date-range-option"
                              value={this.state.dateRange}
                              onChange={this.dateRange}
                            >
                              <option value="7">7 Days</option>
                              <option value="1">1 Month</option>
                              <option value="6">6 Months</option>
                              <option value="custom">Custom Date</option>
                            </select>
                          </div>
                          <div
                            className={`text-center col-md-6 mx-auto ${
                              this.state.dateRange !== "custom" ? "d-none" : ""
                            }`}
                          >
                            <Row form>
                              <Col md={6} className="text-left">
                                <FormGroup className="text-center custom-date-form">
                                  <Label for="exampleEmail">Start Date</Label>
                                  <Input
                                    className="input-date-filter"
                                    type="date"
                                    name="startDate"
                                    placeholder="yyyy-mm-dd"
                                    onChange={this.dateRange}
                                    value={this.state.startDate}
                                    max={moment().format("YYYY-MM-DD")}
                                  />
                                </FormGroup>
                                <Label
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    padding: 5,
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  {this.state.startDateError}
                                </Label>
                              </Col>
                              <Col md={6} className="text-left">
                                <FormGroup className="text-center custom-date-form">
                                  <Label for="examplePassword">End Date</Label>
                                  <Input
                                    className="input-date-filter"
                                    type="date"
                                    name="endDate"
                                    placeholder="yyyy-mm-dd"
                                    onChange={this.dateRange}
                                    value={this.state.endDate}
                                    max={moment().format("YYYY-MM-DD")}
                                  />
                                </FormGroup>
                                <Label
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    padding: 5,
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  {this.state.endDateError}
                                </Label>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                      {!this.props.ongoingPatientProfileRequest &&
                        this.props.ongoingbothReadingRequest && (
                          <LottieLoader
                            loadLoop={true}
                            autoplayLoop={true}
                            source={lottieLoaderFile}
                            Lheight={150}
                            Lwidth={150}
                            subTitle={"Please Wait ..."}
                          />
                        )}
                      {!this.props.ongoingbothReadingRequest &&
                        bodytraceReadings != undefined &&
                        bodytraceReadings.bloodPressure &&
                        bodytraceReadings.bloodPressure.length == 0 &&
                        bodytraceReadings != undefined &&
                        bodytraceReadings.weightScale &&
                        bodytraceReadings.weightScale.length == 0 &&
                        this.props.readings != undefined &&
                        this.props.readings.length == 0 && (
                          <div className="text-center">
                            <LottieLoader
                              loadLoop={false}
                              autoplayLoop={true}
                              source={lottieEmptyFile}
                              Lheight={300}
                              Lwidth={300}
                              title={"Sorry! No reading data found :("}
                              subTitle={"We can't find any reading data."}
                            />
                          </div>
                        )}
                      {!this.props.ongoingbothReadingRequest && (
                        <>
                          <Row>
                            {/* {_.size(devices) == 0 && (
                          <div className="col-12 text-center">
                            <LottieLoader
                              loadLoop={false}
                              autoplayLoop={true}
                              source={lottieEmptyFile}
                              Lheight={300}
                              Lwidth={300}
                              title={"Sorry! No Current Device Assigned :("}
                            />
                          </div>
                        )} */}

                            {this.props.dexcomRaw &&
                              this.props.dexcomRaw.length > 0 && (
                                <DexcomData
                                  dexcomData={this.props.dexcomRaw}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                ></DexcomData>
                              )}

                            {this.props.dexcomGlucoseData &&
                              this.props.dexcomGlucoseData.length > 0 && (
                                <DexcomGlucoseData
                                  dexcomData={this.props.dexcomGlucoseData}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                ></DexcomGlucoseData>
                              )}

                            {/* Bodytrace Blood Pressure Chart */}
                            {bodytraceReadings?.bloodPressure?.length > 0 &&
                              !_.isEmpty(this.state.startDate) &&
                              _.isEmpty(this.state.startDateError) &&
                              _.isEmpty(this.state.endDateError) && (
                                <BodyTraceBPData
                                  bodytraceBP={bodytraceReadings.bloodPressure}
                                  startDate={
                                    _.isEmpty(this.state.startDate)
                                      ? moment().subtract(7, "d").format()
                                      : this.state.startDate
                                  }
                                  endDate={
                                    _.isEmpty(this.state.endDate)
                                      ? moment().format()
                                      : this.state.endDate
                                  }
                                  dateRange={this.state.dateRange}
                                  onChangeDateRange={this.dateRange}
                                  exportReadingCsv={this.exportReadingCsv}
                                  emailReadingReport={this.emailReadingReport}
                                  unitMeasurement={unitMeasurement}
                                  profile={this.props.profile}
                                  expectationRule={expectationRule}
                                  notesPatientDetails={
                                    this.props.notesPatientDetails
                                  }
                                  latestReadingUser={
                                    this.props.latestReadingUser
                                  }
                                  ccmTotalDuration={this.props.ccmTotalDuration}
                                  rpmTotalDuration={this.props.rpmTotalDuration}
                                  ongoingbothReadingRequest={
                                    this.props.ongoingbothReadingRequest
                                  }
                                  ongoingPdfExcelRequest={
                                    this.props.ongoingPdfExcelRequest
                                  }
                                ></BodyTraceBPData>
                              )}
                            {/* Bodytrace Weight Scale Chart */}
                            {bodytraceReadings?.weightScale?.length > 0 &&
                              !_.isEmpty(this.state.startDate) &&
                              _.isEmpty(this.state.startDateError) &&
                              _.isEmpty(this.state.endDateError) && (
                                <BodyTraceWSData
                                  bodytraceWS={bodytraceReadings.weightScale}
                                  startDate={
                                    _.isEmpty(this.state.startDate)
                                      ? moment().subtract(7, "d").format()
                                      : this.state.startDate
                                  }
                                  endDate={
                                    _.isEmpty(this.state.endDate)
                                      ? moment().format()
                                      : this.state.endDate
                                  }
                                  dateRange={this.state.dateRange}
                                  onChangeDateRange={this.dateRange}
                                  exportReadingCsv={this.exportReadingCsv}
                                  emailReadingReport={this.emailReadingReport}
                                  unitMeasurement={unitMeasurement}
                                  profile={this.props.profile}
                                  expectationRule={expectationRule}
                                  expectationWeightRule={expectationWeightRule}
                                  notesPatientDetails={
                                    this.props.notesPatientDetails
                                  }
                                  latestReadingUser={
                                    this.props.latestReadingUser
                                  }
                                  ccmTotalDuration={this.props.ccmTotalDuration}
                                  rpmTotalDuration={this.props.rpmTotalDuration}
                                  ongoingbothReadingRequest={
                                    this.props.ongoingbothReadingRequest
                                  }
                                  ongoingPdfExcelRequest={
                                    this.props.ongoingPdfExcelRequest
                                  }
                                ></BodyTraceWSData>
                              )}

                            {this.props.readings.length > 0 &&
                              !_.isEmpty(this.state.startDate) &&
                              _.isEmpty(this.state.startDateError) &&
                              _.isEmpty(this.state.endDateError) &&
                              this.props.readingType.map((readingType, i) => {
                                const { _id: readingId } = readingType;

                                let dateTo;
                                dateTo = moment().add(1, "d").format();

                                let dateFrom;
                                if (this.state.dateRange == 7) {
                                  dateFrom = moment(
                                    this.state.startDate
                                  ).format();
                                }
                                if (this.state.dateRange == 1) {
                                  dateFrom = moment(
                                    this.state.startDate
                                  ).format();
                                }
                                if (this.state.dateRange == 6) {
                                  dateFrom = moment(
                                    this.state.startDate
                                  ).format();
                                }
                                if (this.state.dateRange == "custom") {
                                  dateFrom = moment(
                                    this.state.startDate
                                  ).format();
                                  dateTo = moment(
                                    !_.isEmpty(this.state.endDate)
                                      ? this.state.endDate
                                      : moment().format()
                                  )
                                    .add(1, "d")
                                    .format();
                                }

                                const bDateFrom = moment(dateFrom)
                                  .clone()
                                  .format("MMMM DD, YYYY");
                                const bDateTo = moment(dateTo)
                                  .clone()
                                  .subtract(1, "d")
                                  .format("MMMM DD, YYYY");
                                // this added code for heart rate take from puls ox filter(HeartRateFromPulsOx)
                                let readingsFilter = this.props.readings;
                                console.log(this.props.isBloodPressureReading);
                                let isBloodPressureReading = this.props
                                  .isBloodPressureReading
                                  ? this.props.isBloodPressureReading
                                  : bodytraceReadings?.isBloodPressureReading;
                                console.log(
                                  isBloodPressureReading,
                                  "isBloodPressureReading"
                                );
                                // finished puls ox filter code(HeartRateFromPulsOx)
                                const infilterReadingType = _.filter(
                                  this.props.readings,
                                  {
                                    readingType: { _id: readingType._id },
                                  }
                                );

                                const orderByTheFilter = _.orderBy(
                                  infilterReadingType,
                                  ["deviceReadingDate"],
                                  ["asc"]
                                );

                                const filterReadingType = _.filter(
                                  orderByTheFilter,
                                  function (value) {
                                    const convertFirst = moment(
                                      value.deviceReadingDate
                                    )
                                      .utc()
                                      .format();
                                    return moment(convertFirst).isBetween(
                                      moment(dateFrom)
                                        .utc()
                                        .startOf("day")
                                        .format(),
                                      moment(dateTo).utc().endOf("day").format()
                                    );
                                  }
                                );
                                if (
                                  !userReadingTypesAvailable.includes(
                                    readingId
                                  ) &&
                                  _.size(filterReadingType) === 0
                                ) {
                                  return;
                                }

                                const readingValue = _.map(
                                  filterReadingType,
                                  "value"
                                );

                                const readingDate = _.map(
                                  filterReadingType,
                                  function (value, index) {
                                    return moment(value.deviceReadingDate)
                                      .tz(moment.tz.guess())
                                      .format();
                                  }
                                );
                                const readingDateNormal = _.map(
                                  filterReadingType,
                                  function (value, index) {
                                    return value.deviceReadingDate;
                                  }
                                );
                                if (
                                  readingValue.length === 0 &&
                                  readingType.abbrev === "EG"
                                ) {
                                  return;
                                }
                                let readingDataSet = [];
                                let readingThreshold = [];
                                const subType = readingType.subTypes;
                                let unit;
                                _.map(subType, function (v, i) {
                                  // this added code for heart rate take from puls ox filter(HeartRateFromPulsOx)
                                  if (
                                    isBloodPressureReading &&
                                    v &&
                                    v._id == "5be56cbe3863cd0647d79896" &&
                                    readingType &&
                                    readingType._id &&
                                    readingType._id ==
                                      "5bb306382598931ffbd1b626"
                                  ) {
                                    return;
                                  }
                                  // finished puls ox filter code(HeartRateFromPulsOx)
                                  unit = v.unit ? "(" + v.unit + ")" : v.unit;
                                  const lowV = _.camelCase(
                                    v.name.replace(
                                      "Oxygen Saturation",
                                      "Blood Oxygen"
                                    ) + "Low"
                                  );
                                  const highV = _.camelCase(
                                    v.name.replace(
                                      "Oxygen Saturation",
                                      "Blood Oxygen"
                                    ) + "High"
                                  );
                                  const newReading = _.map(
                                    filterReadingType,
                                    function (rawvalue, index) {
                                      const value = rawvalue.value;
                                      const readingValueNew =
                                        rawvalue.readingValue;
                                      const insulinDosage =
                                        rawvalue.insulinDosage
                                          ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                          : "";
                                      if (v.name == "Mean Arterial") {
                                        const MAP =
                                          (1 / 3) * value[0] +
                                          (2 / 3) * value[1];
                                        return Math.round(MAP);
                                      }
                                      if (v.name == "Temperature") {
                                        let tempValue;

                                        if (
                                          unitMeasurement.umTemperature === "C"
                                        ) {
                                          // if (
                                          //   threshold[highV] ==
                                          //   originalThreshold[highV]
                                          // ) {
                                          //   threshold[highV] = (
                                          //     (threshold[highV] - 32) /
                                          //     1.8
                                          //   ).toFixed(2);
                                          //   threshold[lowV] = (
                                          //     (threshold[lowV] - 32) /
                                          //     1.8
                                          //   ).toFixed(2);
                                          // }

                                          tempValue = value[i];
                                          unit =
                                            "(" +
                                            unitMeasurement.umTemperature +
                                            ")";
                                        } else {
                                          tempValue = (
                                            (value[i] * 9) / 5 +
                                            32
                                          ).toFixed(2);
                                          unit =
                                            "(" +
                                            unitMeasurement.umTemperature +
                                            ")";
                                        }
                                        return {
                                          y: tempValue,
                                        };
                                      }
                                      if (v.name == "Weight") {
                                        let weightValue;

                                        if (unitMeasurement.umWeight === "kg") {
                                          weightValue = value[i];
                                          unit =
                                            "(" +
                                            unitMeasurement.umWeight +
                                            ")";
                                        } else {
                                          weightValue = value[i] * 2.2046;
                                          unit =
                                            "(" +
                                            unitMeasurement.umWeight +
                                            ")";
                                        }
                                        return { y: Math.round(weightValue) };
                                      }
                                      if (v.name == "BMI") {
                                        let weightValue;
                                        if (readingValueNew) {
                                          const {
                                            bmi = null,
                                            bmiDescription = null,
                                            weight,
                                          } = readingValueNew;
                                          weightValue = bmi;
                                          unit = ``;
                                          return {
                                            y: Math.round(bmi),
                                            eventDescription:
                                              "Description: " + bmiDescription,
                                          };
                                        }
                                      }
                                      if (
                                        v.name == "Systolic" ||
                                        v.name == "Diastolic"
                                      ) {
                                        let pressureValue;

                                        if (
                                          unitMeasurement.umBloodPressure ===
                                          "mmHg"
                                        ) {
                                          pressureValue = value[i];
                                          unit =
                                            "(" +
                                            unitMeasurement.umBloodPressure +
                                            ")";
                                        } else {
                                          pressureValue = value[i] * 0.133322;
                                          unit =
                                            "(" +
                                            unitMeasurement.umBloodPressure +
                                            ")";
                                          // if (
                                          //   threshold[highV] ==
                                          //   originalThreshold[highV]
                                          // ) {
                                          //   threshold[highV] = Math.round(
                                          //     threshold[highV] * 0.133322
                                          //   );
                                          //   threshold[lowV] = Math.round(
                                          //     threshold[lowV] * 0.133322
                                          //   );
                                          // }
                                        }
                                        return { y: Math.round(pressureValue) };
                                      }
                                      if (v.name == "Glucose") {
                                        const eventGlucose = value[1];
                                        let glucoseValue;
                                        if (
                                          unitMeasurement.umGlucose === "mg/dL"
                                        ) {
                                          glucoseValue = Math.round(value[i]);
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        } else {
                                          glucoseValue = Math.round(
                                            value[i] / 18
                                          );
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                          // if (
                                          //   threshold[highV] ==
                                          //   originalThreshold[highV]
                                          // ) {
                                          //   threshold[highV] = Math.round(
                                          //     threshold[highV] * 0.133322
                                          //   );
                                          //   threshold[lowV] = Math.round(
                                          //     threshold[lowV] * 0.133322
                                          //   );
                                          // }
                                        }
                                        let event;
                                        switch (eventGlucose) {
                                          case 0:
                                            event = " (No Event)";
                                            break;
                                          case 3:
                                            event = " (After Meal)";
                                            break;
                                          case 4:
                                            event = " (Before Meal)";
                                            break;
                                          case 5:
                                            event = " (Control Solution)";
                                            break;
                                          default:
                                            event = " (No Event)";
                                            break;
                                        }
                                        return {
                                          y: glucoseValue,
                                          eventDescription:
                                            event + insulinDosage,
                                        };
                                      }
                                      return { y: Math.round(value[i]) };
                                    }
                                  );

                                  const noEvent = _.map(
                                    filterReadingType,
                                    function (rawvalue, index) {
                                      const value = rawvalue.value;
                                      const insulinDosage =
                                        rawvalue.insulinDosage
                                          ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                          : "";
                                      if (v.name == "Glucose") {
                                        let glucoseValue;
                                        if (
                                          unitMeasurement.umGlucose === "mg/dL"
                                        ) {
                                          glucoseValue = Math.round(value[i]);
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        } else {
                                          glucoseValue = Math.round(
                                            value[i] / 18
                                          );
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        }
                                        const eventGlucose = value[1];

                                        let event;
                                        switch (eventGlucose) {
                                          case 0:
                                            event = " (No Event)";
                                            return {
                                              y: glucoseValue,
                                              eventDescription:
                                                event + insulinDosage,
                                            };
                                            break;
                                        }
                                      }
                                    }
                                  );

                                  const beforeMeal = _.map(
                                    filterReadingType,
                                    function (rawvalue, index) {
                                      const value = rawvalue.value;
                                      const insulinDosage =
                                        rawvalue.insulinDosage
                                          ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                          : "";
                                      if (v.name == "Glucose") {
                                        let glucoseValue;
                                        if (
                                          unitMeasurement.umGlucose === "mg/dL"
                                        ) {
                                          glucoseValue = Math.round(value[i]);
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        } else {
                                          glucoseValue = Math.round(
                                            value[i] / 18
                                          );
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        }
                                        const eventGlucose = value[1];

                                        let event;
                                        switch (eventGlucose) {
                                          case 4:
                                            event = " (Before Meal)";
                                            return {
                                              y: glucoseValue,
                                              eventDescription:
                                                event + insulinDosage,
                                            };
                                            break;
                                        }
                                      }
                                    }
                                  );
                                  const glucoseWithInsulinDosage = _.map(
                                    filterReadingType,
                                    function (rawvalue, index) {
                                      const value = rawvalue.value;
                                      const insulinDosage =
                                        rawvalue.insulinDosage
                                          ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                          : null;
                                      if (v.name == "Glucose") {
                                        let glucoseValue;
                                        if (
                                          unitMeasurement.umGlucose === "mg/dL"
                                        ) {
                                          glucoseValue = Math.round(value[i]);
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        } else {
                                          glucoseValue = Math.round(
                                            value[i] / 18
                                          );
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        }

                                        if (insulinDosage) {
                                          return {
                                            y: glucoseValue,
                                            eventDescription: insulinDosage,
                                          };
                                        }
                                      }
                                    }
                                  );
                                  const afterMeal = _.map(
                                    filterReadingType,
                                    function (rawvalue, index) {
                                      const value = rawvalue.value;
                                      const insulinDosage =
                                        rawvalue.insulinDosage
                                          ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                          : "";
                                      if (v.name == "Glucose") {
                                        let glucoseValue;
                                        if (
                                          unitMeasurement.umGlucose === "mg/dL"
                                        ) {
                                          glucoseValue = Math.round(value[i]);
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        } else {
                                          glucoseValue = Math.round(
                                            value[i] / 18
                                          );
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        }
                                        const eventGlucose = value[1];

                                        let event;
                                        switch (eventGlucose) {
                                          case 3:
                                            event = " (After Meal)";
                                            return {
                                              y: glucoseValue,
                                              eventDescription:
                                                event + insulinDosage,
                                            };
                                            break;
                                        }
                                      }
                                    }
                                  );
                                  const controlSolution = _.map(
                                    filterReadingType,
                                    function (rawvalue, index) {
                                      const value = rawvalue.value;
                                      const insulinDosage =
                                        rawvalue.insulinDosage
                                          ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                          : "";
                                      if (v.name == "Glucose") {
                                        const eventGlucose = value[1];
                                        let glucoseValue;
                                        if (
                                          unitMeasurement.umGlucose === "mg/dL"
                                        ) {
                                          glucoseValue = Math.round(value[i]);
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        } else {
                                          glucoseValue = Math.round(
                                            value[i] / 18
                                          );
                                          unit =
                                            "(" +
                                            unitMeasurement.umGlucose +
                                            ")";
                                        }
                                        let event;
                                        switch (eventGlucose) {
                                          case 5:
                                            event = " (Control Solution)";
                                            return {
                                              y: glucoseValue,
                                              eventDescription:
                                                event + insulinDosage,
                                            };
                                            break;
                                        }
                                      }
                                    }
                                  );
                                  const matchingExpectationsBreached = _.filter(
                                    expectationRule,
                                    function (o) {
                                      return (
                                        o &&
                                        (o.readingSubType == v.name ||
                                          o.readingSubType == v.unit) &&
                                        !o.name
                                          .toLowerCase()
                                          .includes("Event".toLowerCase())
                                      );
                                    }
                                  );
                                  const matchingExpectationsWeightBreached =
                                    _.filter(
                                      expectationWeightRule,
                                      function (o) {
                                        return (
                                          o &&
                                          (o.ruleType.toLowerCase() ==
                                            v.name.toLowerCase() ||
                                            o.readingSubType == v.unit) &&
                                          !o.name
                                            .toLowerCase()
                                            .includes("Event".toLowerCase())
                                        );
                                      }
                                    );
                                  // Checked all of the readings to see which had a low threshold.
                                  var lowExpectation = _.flatMap(
                                    _(matchingExpectationsBreached)
                                      .filter(
                                        (o) =>
                                          (o && o.condition == "<=") ||
                                          o.condition == "between"
                                      )
                                      .flatMap((_value, i) =>
                                        _.pick(_value, "valueProperty")
                                      )
                                      .value(),
                                    "valueProperty"
                                  );

                                  var highExpectation = _.flatMap(
                                    _(matchingExpectationsBreached)
                                      .filter(
                                        (o) =>
                                          (o && o.condition == ">=") ||
                                          o.condition == "between"
                                      )
                                      .flatMap((_value, i) =>
                                        _.pick(_value, "valueProperty")
                                      )
                                      .value(),
                                    "valueProperty"
                                  );

                                  var lowValueThreshold = _.maxBy(
                                    _.map(
                                      lowExpectation,
                                      (i) => i.minValue || i.value
                                    ),
                                    function (o) {
                                      return o && parseInt(o);
                                    }
                                  );
                                  var highValueThreshold = _.minBy(
                                    _.map(
                                      highExpectation,
                                      (i) => i.maxValue || i.value
                                    ),
                                    function (o) {
                                      return o && parseInt(o);
                                    }
                                  );
                                  if (
                                    matchingExpectationsWeightBreached &&
                                    _.size(matchingExpectationsWeightBreached) >
                                      0
                                  ) {
                                    const weightGain = _.max(
                                      _.map(
                                        matchingExpectationsWeightBreached,
                                        (model) => model.gainLossPerDay
                                      )
                                    );
                                    readingThreshold.push({
                                      lowThreshold: lowValueThreshold,
                                      highThreshold: highValueThreshold,
                                      isWeight: true,
                                      weightGain: weightGain,
                                      readingSubTypeName: v.name,
                                    });
                                  } else {
                                    if (v.name != "BMI") {
                                      readingThreshold.push({
                                        lowThreshold: lowValueThreshold,
                                        highThreshold: highValueThreshold,
                                        readingSubTypeName: v.name,
                                      });
                                    }
                                  }
                                  const lowThreshold = _.map(
                                    readingValue,
                                    function (value, index) {
                                      const lowValue = lowValueThreshold
                                        ? lowValueThreshold
                                        : 0;
                                      return {
                                        y: lowValue,
                                      };
                                    }
                                  );
                                  const highThreshold = _.map(
                                    readingValue,
                                    function (value, index) {
                                      const highValue = highValueThreshold
                                        ? highValueThreshold
                                        : 0;
                                      return {
                                        y: highValue,
                                      };
                                    }
                                  );
                                  const pointedColor = _.map(
                                    readingValue,
                                    function (value, index) {
                                      if (v.name == "Glucose") {
                                        const eventGlucose = value[1];

                                        let event;
                                        switch (eventGlucose) {
                                          case 0:
                                            event = "blue";
                                            break;
                                          case 3:
                                            event = "red";
                                            break;
                                          case 4:
                                            event = "orange";
                                            break;
                                          case 5:
                                            event = "green";
                                            break;
                                          default:
                                            event = "blue";
                                            break;
                                        }
                                        return event;
                                      }
                                    }
                                  );

                                  if (v.name === "Glucose") {
                                    readingDataSet.push({
                                      label:
                                        "Glucose Reading with Insulin Dosage",
                                      fill: false,
                                      pointRadius: 8,
                                      pointHoverRadius: 8,
                                      backgroundColor: "pink",
                                      borderColor: "blue",
                                      pointBackgroundColor: "pink",
                                      data: glucoseWithInsulinDosage,
                                    });
                                    readingDataSet.push({
                                      label: v.name + unit,
                                      fill: false,
                                      pointRadius: 4,
                                      pointHoverRadius: 4,
                                      backgroundColor: v.color,
                                      borderColor: v.color,
                                      pointBackgroundColor: pointedColor,
                                      data: newReading,
                                    });
                                    if (lowValueThreshold > 0) {
                                      readingDataSet.push({
                                        label: `Low (${v.name})`,
                                        fill: false,
                                        radius: 0,
                                        pointRadius: 1,
                                        pointHoverRadius: 1,
                                        borderWidth: 2,
                                        borderDash: [1, 3],
                                        borderColor: v.color,
                                        pointBackgroundColor: "transparent",
                                        data: lowThreshold,
                                      });
                                    }

                                    if (highValueThreshold > 0) {
                                      readingDataSet.push({
                                        label: `High (${v.name})`,
                                        fill: false,
                                        pointRadius: 1,
                                        pointHoverRadius: 1,
                                        borderWidth: 2,
                                        borderDash: [5, 5],
                                        borderColor: v.color,
                                        pointBackgroundColor: "transparent",
                                        data: highThreshold,
                                      });
                                    }

                                    readingDataSet.push({
                                      label: "No Event",
                                      fill: false,
                                      pointRadius: 4,
                                      pointHoverRadius: 4,
                                      backgroundColor: "blue",
                                      borderColor: "blue",
                                      pointBackgroundColor: pointedColor,
                                      data: noEvent,
                                    });
                                    readingDataSet.push({
                                      label: "Before Meal",
                                      fill: false,
                                      pointRadius: 4,
                                      pointHoverRadius: 4,
                                      backgroundColor: "orange",
                                      borderColor: "orange",
                                      pointBackgroundColor: pointedColor,
                                      data: beforeMeal,
                                    });
                                    readingDataSet.push({
                                      label: "After Meal",
                                      fill: false,
                                      pointRadius: 4,
                                      pointHoverRadius: 4,
                                      backgroundColor: "red",
                                      borderColor: "red",
                                      pointBackgroundColor: pointedColor,
                                      data: afterMeal,
                                    });
                                    readingDataSet.push({
                                      label: "Control Solution",
                                      fill: false,
                                      pointRadius: 4,
                                      pointHoverRadius: 4,
                                      backgroundColor: "green",
                                      borderColor: "green",
                                      pointBackgroundColor: pointedColor,
                                      data: controlSolution,
                                    });
                                  } else {
                                    if (lowValueThreshold > 0) {
                                      readingDataSet.push({
                                        label: `Low (${v.name})`,
                                        fill: false,
                                        radius: 0,
                                        pointRadius: 1,
                                        pointHoverRadius: 1,
                                        borderWidth: 2,
                                        borderDash: [1, 3],
                                        backgroundColor: v.color,
                                        borderColor: v.color,
                                        pointBackgroundColor: "transparent",
                                        data: lowThreshold,
                                      });
                                    }
                                    if (highValueThreshold > 0) {
                                      readingDataSet.push({
                                        label: `High (${v.name})`,
                                        fill: false,
                                        pointRadius: 1,
                                        pointHoverRadius: 1,
                                        borderWidth: 2,
                                        borderDash: [5, 5],
                                        backgroundColor: v.color,
                                        borderColor: v.color,
                                        pointBackgroundColor: "transparent",
                                        data: highThreshold,
                                      });
                                    }
                                    if (v.name != "BMI") {
                                      readingDataSet.push({
                                        label: v.name + unit,
                                        fill: false,
                                        pointRadius: 4,
                                        pointHoverRadius: 4,
                                        backgroundColor: v.color,
                                        borderColor: v.color,
                                        data: newReading,
                                      });
                                    }
                                  }
                                });
                                return (
                                  <>
                                    {readingDate && readingDate.length > 0 && (
                                      <Col sm="6" className="mt-4">
                                        {readingDate &&
                                          readingDate.length > 0 && (
                                            <Card
                                              className="card-design"
                                              style={{
                                                backgroundColor: "#fffff",
                                              }}
                                            >
                                              <CardBody className="line-graph-card-padding">
                                                <div class="card-title row">
                                                  <div class="col-9">
                                                    <div>
                                                      <div className="text-left reading-card-for-all-reading">
                                                        <img
                                                          src={require(`../../files/Reading/${readingType.abbrev}.svg`)}
                                                          alt="profile-image"
                                                          className="profile rounded-circle img-fluid mr-2 reading-icon"
                                                        />{" "}
                                                        <div>
                                                          <h4 class="reading-card-title">
                                                            {
                                                              readingType.description
                                                            }
                                                          </h4>
                                                          <h6 class="reading-card-subtitle">{`${bDateFrom} - ${bDateTo}`}</h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="col-3 show-more-button-center">
                                                    <a
                                                      target="_blank"
                                                      onClick={() =>
                                                        this.toggleModal(i)
                                                      }
                                                      className="text-white ml-auto btn btn-sm display-chart-show-more"
                                                    >
                                                      Show More Details
                                                    </a>
                                                  </div>
                                                </div>
                                                <LineGraph
                                                  readingDate={readingDate}
                                                  readingDataSet={
                                                    readingDataSet
                                                  }
                                                  hidePrint="d-none"
                                                  dontRender="true"
                                                  getImageValue="1"
                                                  onChange={
                                                    this.handleImageValue
                                                  }
                                                  title={`(${bDateFrom} - ${bDateTo})`}
                                                />
                                                <div />
                                              </CardBody>
                                              <DisplayCardThreshold
                                                readingThreshold={
                                                  readingThreshold
                                                }
                                              />
                                            </Card>
                                          )}
                                        <Modal
                                          isOpen={this.state["gwapo" + i]}
                                          toggle={() => this.toggleModal(i)}
                                          className="modal-lg modal-dialog-centered"
                                          style={{ maxWidth: "77%" }}
                                        >
                                          <ModalHeader
                                            className="close_button"
                                            style={{
                                              float: "right",
                                              padding: 0,
                                            }}
                                            toggle={() => this.toggleModal(i)}
                                          >
                                            <Label className="align-items-center justify-content-center text-center">
                                              {readingType.description}
                                            </Label>
                                            <button
                                              style={{ float: "right" }}
                                              onClick={() => {
                                                this.toggleModal(i);
                                              }}
                                              className="btn btn-info ml-2 close-click-cls"
                                            >
                                              <i
                                                class="mdi mdi-close"
                                                style={{
                                                  fontSize: 20,
                                                }}
                                              ></i>
                                              Close
                                            </button>
                                            <ReactToPrint
                                              trigger={() => (
                                                <div className="pull-right">
                                                  <button
                                                    className="btn btn-info ml-2 print-click-cls"
                                                    style={{ float: "right" }}
                                                  >
                                                    <i
                                                      class="mdi mdi-cloud-print"
                                                      style={{
                                                        fontSize: 20,
                                                      }}
                                                    ></i>
                                                    Print Graph
                                                  </button>
                                                </div>
                                              )}
                                              content={() =>
                                                this.componentGraphRef
                                              }
                                            />
                                            <ReactToPrint
                                              trigger={() => (
                                                <div className="pull-right">
                                                  <button
                                                    className="btn btn-info ml-2 print-data-table-click-cls"
                                                    style={{ float: "right" }}
                                                  >
                                                    <i
                                                      class="mdi mdi-cloud-print"
                                                      style={{
                                                        fontSize: 20,
                                                      }}
                                                    ></i>
                                                    Print Logbook
                                                  </button>
                                                </div>  
                                              )}
                                              content={() =>
                                                this.componentLogRef
                                              }
                                            />
                                            <ReactToPrint
                                              trigger={() => (
                                                <div className="pull-right">
                                                  <button
                                                    className="btn btn-info ml-2 print-whole-data-table-click-cls"
                                                    style={{ float: "right" }}
                                                  >
                                                    <i
                                                      class="mdi mdi-cloud-print"
                                                      style={{
                                                        fontSize: 20,
                                                      }}
                                                    ></i>
                                                    Print Graph & Logs
                                                  </button>
                                                </div>
                                              )}
                                              content={() =>
                                                this.componentBothRef
                                              }
                                            />
                                            {/* <button
                                      className="btn btn-info ml-3 print-graph-table-click-cls"
                                      style={{ float: 'right' }}
                                      onClick={() => {
                                                                                
                                      }}
                                    >
                                      <i class="mdi mdi-cloud-print" style={{
                                        fontSize: 20
                                      }}></i>
                                      Print Graph & Reading
                                    </button> */}

                                            <button
                                              className="btn btn-info ml-2 download-click-cls"
                                              style={{ float: "right" }}
                                              onClick={() => {
                                                // this.tableExportRef.current.click();
                                                this.exportReadingCsv(
                                                  readingType._id,
                                                  false,
                                                  false,
                                                  isBloodPressureReading
                                                );
                                                this.setState({});
                                              }}
                                            >
                                              <i
                                                class="mdi mdi-download"
                                                style={{
                                                  fontSize: 20,
                                                }}
                                              ></i>
                                              Export CSV
                                            </button>
                                            <button
                                              className="btn btn-info ml-2 reading-report-email"
                                              style={{ float: "right" }}
                                              onClick={async () => {
                                                const { value: email } =
                                                  await Swal.fire({
                                                    title: "Reading Report!",
                                                    input: "email",
                                                    inputLabel:
                                                      "Your email address",
                                                    inputPlaceholder:
                                                      "Enter your email address",
                                                    confirmButtonText: "Sent",
                                                    showCancelButton: false,
                                                  });
                                                if (email) {
                                                  this.emailReadingReport(
                                                    readingType._id,
                                                    false,
                                                    false,
                                                    email,
                                                    isBloodPressureReading
                                                  );
                                                }
                                              }}
                                            >
                                              <i
                                                class="mdi mdi-email"
                                                style={{
                                                  fontSize: 20,
                                                }}
                                              ></i>
                                              Email
                                            </button>
                                          </ModalHeader>
                                          <ModalBody
                                            className="close_button"
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <div className="text-center">
                                              <select
                                                name="dateRange"
                                                className="date-range-option"
                                                value={this.state.dateRange}
                                                onChange={this.dateRange}
                                              >
                                                <option value="7">
                                                  7 Days
                                                </option>
                                                <option value="1">
                                                  1 Month
                                                </option>
                                                <option value="6">
                                                  6 Months
                                                </option>
                                                <option value="custom">
                                                  Custom Date
                                                </option>
                                              </select>
                                            </div>
                                            <div
                                              className={`text-center col-md-6 mx-auto ${
                                                this.state.dateRange !==
                                                "custom"
                                                  ? "d-none"
                                                  : ""
                                              }`}
                                            >
                                              <Row form>
                                                <Col md={6}>
                                                  <FormGroup>
                                                    <Label for="exampleEmail">
                                                      Start Date
                                                    </Label>
                                                    <Input
                                                      className="input-date-filter remove-bottom-margin"
                                                      type="date"
                                                      name="startDate"
                                                      onChange={this.dateRange}
                                                      value={
                                                        this.state.startDate
                                                      }
                                                      max={moment().format(
                                                        "YYYY-MM-DD"
                                                      )}
                                                    />
                                                  </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <FormGroup>
                                                    <Label for="examplePassword">
                                                      End Date
                                                    </Label>
                                                    <Input
                                                      className="input-date-filter remove-bottom-margin"
                                                      type="date"
                                                      name="endDate"
                                                      onChange={this.dateRange}
                                                      value={this.state.endDate}
                                                      max={moment().format(
                                                        "YYYY-MM-DD"
                                                      )}
                                                    />
                                                  </FormGroup>
                                                </Col>
                                              </Row>
                                            </div>
                                            {(this.props
                                              .ongoingbothReadingRequest ||
                                              this.props
                                                .ongoingPdfExcelRequest) && (
                                              <LottieLoader
                                                loadLoop={true}
                                                autoplayLoop={true}
                                                source={lottieLoaderFile}
                                                Lheight={150}
                                                Lwidth={150}
                                                subTitle={"Please Wait ..."}
                                              />
                                            )}
                                            {!this.props
                                              .ongoingbothReadingRequest &&
                                              !this.props
                                                .ongoingPdfExcelRequest && (
                                                <>
                                                  <LineGraph
                                                    ref={this.printGraph}
                                                    value={i}
                                                    readingDate={readingDate}
                                                    readingDataSet={
                                                      readingDataSet
                                                    }
                                                    getImageValue="1"
                                                    onChange={
                                                      this.handleImageValue
                                                    }
                                                    title={`${this.props.profile.fname} ${this.props.profile.lname} ${readingType.description} (${bDateFrom} - ${bDateTo})`}
                                                  />
                                                  <hr></hr>
                                                  <DisplayCardThreshold
                                                    isHideBorder={true}
                                                    leftAline={true}
                                                    readingThreshold={
                                                      readingThreshold
                                                    }
                                                  />
                                                  <TableReading
                                                    ref={this.tableExportRef}
                                                    reading={filterReadingType}
                                                    subType={subType}
                                                    readingValue={readingValue}
                                                    unitMeasurement={
                                                      unitMeasurement
                                                    }
                                                    date={`(${bDateFrom} - ${bDateTo})`}
                                                    fname={
                                                      this.props.profile.fname
                                                    }
                                                    lname={
                                                      this.props.profile.lname
                                                    }
                                                    isBloodPressureReading={
                                                      isBloodPressureReading
                                                    }
                                                  />
                                                </>
                                              )}
                                            <div className="hide">
                                              <TableReadingPrint
                                                ref={(el) =>
                                                  (this.componentRef = el)
                                                }
                                                readingType={readingType}
                                                reading={filterReadingType}
                                                subType={subType}
                                                readingValue={readingValue}
                                                unitMeasurement={
                                                  unitMeasurement
                                                }
                                                startDate={bDateFrom}
                                                endDate={bDateTo}
                                                date={`(${bDateFrom} - ${bDateTo})`}
                                                fname={this.props.profile.fname}
                                                lname={this.props.profile.lname}
                                                isBloodPressureReading={
                                                  isBloodPressureReading
                                                }
                                              />
                                            </div>
                                            <div className="hideChart">
                                              <GraphTableReading
                                                ref={(el) =>
                                                  (this.componentLogRef = el)
                                                }
                                                value={i}
                                                readingThreshold={
                                                  readingThreshold
                                                }
                                                readingDate={readingDate}
                                                readingDataSet={readingDataSet}
                                                readingType={readingType}
                                                reading={filterReadingType}
                                                subType={subType}
                                                readingValue={readingValue}
                                                unitMeasurement={
                                                  unitMeasurement
                                                }
                                                startDate={bDateFrom}
                                                endDate={bDateTo}
                                                date={`(${bDateFrom} - ${bDateTo})`}
                                                fname={this.props.profile.fname}
                                                lname={this.props.profile.lname}
                                                notesPatientDetails={
                                                  this.props.notesPatientDetails
                                                }
                                                latestReadingUser={
                                                  this.props.latestReadingUser
                                                }
                                                ccmTotalDuration={
                                                  this.props.ccmTotalDuration
                                                }
                                                rpmTotalDuration={
                                                  this.props.rpmTotalDuration
                                                }
                                                callingThroughValue="logbook"
                                                isBloodPressureReading={
                                                  isBloodPressureReading
                                                }
                                              />
                                            </div>
                                            <div className="hideChart">
                                              <GraphTableReading
                                                ref={(el) =>
                                                  (this.componentBothRef = el)
                                                }
                                                value={i}
                                                readingThreshold={
                                                  readingThreshold
                                                }
                                                readingDate={readingDate}
                                                readingDataSet={readingDataSet}
                                                readingType={readingType}
                                                reading={filterReadingType}
                                                subType={subType}
                                                readingValue={readingValue}
                                                unitMeasurement={
                                                  unitMeasurement
                                                }
                                                startDate={bDateFrom}
                                                endDate={bDateTo}
                                                date={`(${bDateFrom} - ${bDateTo})`}
                                                fname={this.props.profile.fname}
                                                lname={this.props.profile.lname}
                                                notesPatientDetails={
                                                  this.props.notesPatientDetails
                                                }
                                                latestReadingUser={
                                                  this.props.latestReadingUser
                                                }
                                                ccmTotalDuration={
                                                  this.props.ccmTotalDuration
                                                }
                                                rpmTotalDuration={
                                                  this.props.rpmTotalDuration
                                                }
                                                callingThroughValue="graphlogbook"
                                                isBloodPressureReading={
                                                  isBloodPressureReading
                                                }
                                              />
                                            </div>
                                            <div className="hideChart">
                                              <GraphTableReading
                                                ref={(el) =>
                                                  (this.componentGraphRef = el)
                                                }
                                                value={i}
                                                readingThreshold={
                                                  readingThreshold
                                                }
                                                readingDate={readingDate}
                                                readingDataSet={readingDataSet}
                                                readingType={readingType}
                                                reading={filterReadingType}
                                                subType={subType}
                                                readingValue={readingValue}
                                                unitMeasurement={
                                                  unitMeasurement
                                                }
                                                startDate={bDateFrom}
                                                endDate={bDateTo}
                                                date={`(${bDateFrom} - ${bDateTo})`}
                                                fname={this.props.profile.fname}
                                                lname={this.props.profile.lname}
                                                notesPatientDetails={
                                                  this.props.notesPatientDetails
                                                }
                                                latestReadingUser={
                                                  this.props.latestReadingUser
                                                }
                                                ccmTotalDuration={
                                                  this.props.ccmTotalDuration
                                                }
                                                rpmTotalDuration={
                                                  this.props.rpmTotalDuration
                                                }
                                                callingThroughValue="graph"
                                                isBloodPressureReading={
                                                  isBloodPressureReading
                                                }
                                              />
                                            </div>
                                          </ModalBody>
                                        </Modal>
                                      </Col>
                                    )}
                                  </>
                                );
                              })}
                          </Row>
                        </>
                      )}
                    </section>
                    <section
                      id="section-iconbox-2"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "2",
                      })}
                    >
                      {this.state.activeTab === '2' && (
                        <MainViolationsComponent patientId={this.props.profile?._id} isCareProviderPatient={true} />
                      )}   
                    </section>
                    <section
                      id="section-iconbox-3"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "3",
                      })}
                    >
                      <div className={`text-center col-md-6 mx-auto`}>
                        <h5 className="mb-4">
                          Pick Date Range to Count the Grand Total of PM
                          SESSIONS{" "}
                        </h5>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="exampleEmail">Pick the Month</Label>
                              <select
                                className="form-control"
                                value={this.state.month}
                                onChange={this.handleInputChange}
                                name="month"
                              >
                                <option value="" />
                                {monthNames.map((value, index) => {
                                  return <option value={value}>{value}</option>;
                                })}
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="exampleEmail">Pick the Year</Label>
                              <select
                                className="form-control"
                                value={this.state.year}
                                onChange={this.handleInputChange}
                                name="year"
                              >
                                <option value="" />
                                {yearList.map((value, index) => {
                                  return <option value={value}>{value}</option>;
                                })}
                              </select>
                            </FormGroup>
                          </Col>
                          {/* <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">Start Date</Label>
                          <Input
                            type="date"
                            name="startDateForPM"
                            onChange={this.dateRange}
                            value={this.state.startDateForPM}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="examplePassword">End Date</Label>
                          <Input
                            type="date"
                            name="endDateForPM"
                            onChange={this.dateRange}
                            value={this.state.endDateForPM}
                          />
                        </FormGroup>
                      </Col> */}
                        </Row>
                      </div>
                      {this.state.interventionData && (
                        <CCMintervention
                          profile={this.props.profile}
                          profileOwner={this.props.profileOwner}
                          startDate={this.state.startDateForPM}
                          endDate={this.state.endDateForPM}
                          details={this.state.interventionData}
                          modal={this.state.openInterventionReport}
                          openInterventionReport={this.openInterventionReport}
                        />
                      )}
                      {this.props.pmRecords && (
                        <CCMList
                          pmRecords={pmFiltered}
                          userId={this.props.userId}
                          pleaseExpand={true}
                          startDate={this.state.startDateForPM}
                          endDate={this.state.endDateForPM}
                          openInterventionReport={this.openInterventionReport}
                        />
                      )}
                      {!this.props.pmRecords && (
                        <LottieLoader
                          loadLoop={true}
                          autoplayLoop={true}
                          source={lottieLoaderFile}
                          Lheight={150}
                          Lwidth={150}
                          subTitle={"Please Wait ..."}
                        />
                      )}
                    </section>
                    <section
                      id="section-iconbox-4"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "4",
                      })}
                    >
                      <Notes
                        hideNotesButton={this.props.hideNotesButton}
                        datesForNotes={this.datesForNotes}
                        currentUserId={
                          (this.props.match && this.props.match.params?.id) ??
                          this.props.profile._id
                        }
                      />
                    </section>
                    <section
                      id="section-iconbox-5"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "5",
                      })}
                    >
                      {this.props.profile && this.state.activeTab === "5" && (
                        <Row className="remove-table-padding">
                          <Col md={5} className="remove-table-padding">
                            <ProfileDetail
                              hideTitle
                              enableUserEdit={false}
                              currentUserId={this.props.profile._id}
                            />
                          </Col>
                          <Col md={7} className="remove-table-padding">
                            <Devices
                              hideTitle
                              patient="gwapo"
                              currentUserId={this.props.profile._id}
                              deviceList={this.props.devices}
                            />
                            <TestingFrequency
                              hideTitle
                              patient="gwapo"
                              currentUserId={this.props.profile._id}
                              readingFrequency={this.props.readingFrequency}
                              ongoingTestingFrequencyRequest={
                                this.props.ongoingTestingFrequencyRequest
                              }
                              totalReadingFrequency={
                                this.props.totalReadingFrequency
                              }
                              profile={this.props.profile}
                              userDetails={this.props.userDetails.userDetails}
                            />
                            {this.props.profile.role === "patient" &&
                              !this.props.profile.isStaff &&
                              !this.props.profile.isCareProvider && (
                                <>
                                  <ConfigerReports
                                    profile={this.props.profile}
                                    userDetails={
                                      this.props.userDetails.userDetails
                                    }
                                  />
                                </>
                              )}
                          </Col>
                        </Row>
                      )}
                    </section>
                    <section
                      id="section-iconbox-7"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "7",
                      })}
                    >
                      {(this.state.loadingCarePlan ||
                        this.props.ongoingRequestCarePlan) && (
                        <LottieLoader
                          loadLoop={true}
                          autoplayLoop={true}
                          source={lottieLoaderFile}
                          Lheight={150}
                          Lwidth={150}
                          subTitle={"Please Wait ..."}
                        />
                      )}

                      {/* CCM CarePlan Form */}
                      {(this.props.profile.enrolledPrograms == "CCM&RPM" ||
                        this.props.profile.enrolledPrograms == "CCM") && (
                        <>
                          <div className="care-plan-header-background ">
                            <Row>
                              <Col
                                sm="8"
                                className="center-label remove-table-padding"
                              >
                                <h3
                                  className="text-center title-device"
                                  style={{ marginLeft: 25 }}
                                >
                                  <i class="mdi mdi-medical-bag"></i> CCM Care
                                  Plan Details
                                </h3>
                              </Col>
                              <Col sm="4" className="float-right">
                                {this.props.ccmSubmissinLogs &&
                                  this.props.ccmSubmissinLogs.length > 0 && (
                                    <button
                                      className="btn btn-info ml-2 view-ccm-form-log"
                                      style={{ float: "right" }}
                                      onClick={() => {
                                        this.toggleViewCCMFormLogs();
                                      }}
                                    >
                                      <i class="mdi mdi-eye"></i>
                                      CCM Care Plan Logs
                                    </button>
                                  )}
                                {this.props.ccmSubmissinLogs &&
                                  !this.props.ccmSubmissinLogs.length > 0 && (
                                    <button
                                      className="btn btn-info ml-2 add-ccm-form"
                                      style={{ float: "right" }}
                                      onClick={() => {
                                        this.toggleAddCCMForm();
                                      }}
                                    >
                                      <i class="mdi mdi-clipboard-plus"></i>
                                      CCM Care Plan
                                    </button>
                                  )}
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              keyField="_id"
                              bordered={true}
                              hover={true}
                              condensed={true}
                              noDataIndication={() => (
                                <div>
                                  <LottieLoader
                                    loadLoop={false}
                                    autoplayLoop={true}
                                    source={lottieEmptyFile}
                                    Lheight={150}
                                    Lwidth={150}
                                    titleSmall={"Sorry! No submission found :("}
                                    subTitleSmall={
                                      "We can't find any submission."
                                    }
                                  />
                                </div>
                              )}
                              data={
                                this.props.latestSubmission
                                  ? this.props.latestSubmission
                                  : []
                              }
                              columns={[
                                {
                                  dataField: "description",
                                  text: "Created By",
                                  formatter: (cell, row) => {
                                    if (!row.createdBy) {
                                      return (
                                        <div className="text-left"> - </div>
                                      );
                                    }
                                    return (
                                      <div className="text-left">
                                        {row.createdBy.fname}{" "}
                                        {row.createdBy.lname}
                                      </div>
                                    );
                                  },
                                  headerStyle: (colum, colIndex) => {
                                    return {
                                      textAlign: "center",
                                      padding: "16px",
                                      fontSize: "16px",
                                      color: "#070707",
                                    };
                                  },
                                },
                                {
                                  dataField: "description",
                                  text: "Last Modify By",
                                  formatter: (cell, row) => {
                                    if (!row.modifyBy) {
                                      return (
                                        <div className="text-left">
                                          {" "}
                                          Initial Form{" "}
                                        </div>
                                      );
                                    }
                                    return (
                                      <div className="text-left">
                                        {row.modifyBy.fname}{" "}
                                        {row.modifyBy.lname}
                                      </div>
                                    );
                                  },
                                  headerStyle: (colum, colIndex) => {
                                    return {
                                      textAlign: "center",
                                      padding: "16px",
                                      fontSize: "16px",
                                      color: "#070707",
                                    };
                                  },
                                },
                                {
                                  dataField: "createdDate",
                                  text: "Created Date",
                                  formatter: (cell, row) => {
                                    if (row.createdDate) {
                                      return moment(row.createdDate)
                                        .tz(moment.tz.guess())
                                        .format("MMMM DD, YYYY HH:mm:ss");
                                    } else {
                                      return "NONE";
                                    }
                                  },
                                  headerStyle: (colum, colIndex) => {
                                    return {
                                      textAlign: "center",
                                      padding: "16px",
                                      fontSize: "16px",
                                      color: "#070707",
                                    };
                                  },
                                },
                                {
                                  dataField: "description",
                                  text: "Last Modify Date",
                                  formatter: (cell, row) => {
                                    if (row.modifyDate) {
                                      return moment(row.modifyDate)
                                        .tz(moment.tz.guess())
                                        .format("MMMM DD, YYYY HH:mm:ss");
                                    } else {
                                      return "NONE";
                                    }
                                  },
                                  headerStyle: (colum, colIndex) => {
                                    return {
                                      textAlign: "center",
                                      padding: "16px",
                                      fontSize: "16px",
                                      color: "#070707",
                                    };
                                  },
                                },
                                {
                                  text: "Action",
                                  headerStyle: (colum, colIndex) => {
                                    return {
                                      width: "200px",
                                      padding: "16px",
                                      fontSize: "16px",
                                      color: "#070707",
                                    };
                                  },
                                  formatter: (cell, row) => {
                                    return (
                                      <div>
                                        <button
                                          type="button"
                                          className="btn btn-info ml-2 add-ccm-form"
                                          onClick={() => {
                                            this.toggleViewCCMFormDetails();
                                          }}
                                        >
                                          <i class="mdi mdi-eye"></i>&nbsp;View
                                        </button>
                                      </div>
                                    );
                                  },
                                },
                              ]}
                            />
                          </div>
                        </>
                      )}
                      {/* Prescribed Conditions */}
                      {!this.state.loadingCarePlan &&
                        !this.props.ongoingRequestCarePlan &&
                        this.props.patientConditions && (
                          <>
                            <div className="care-plan-header-background ">
                              <Row>
                                <Col
                                  sm="8"
                                  className="center-label remove-table-padding"
                                >
                                  <h3
                                    className="text-center title-device"
                                    style={{ marginLeft: 25 }}
                                  >
                                    <i class="mdi mdi-medical-bag"></i>{" "}
                                    Prescribed Conditions
                                  </h3>
                                </Col>
                                {(this.props.isOtherRole == false && this.props.staffRole != 'Shared/Family Account') &&(
                                <Col sm="4" className="float-right">
                                  <button
                                    className="btn btn-info ml-2 create-note-click-cls"
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      this.toggleAddConditions();
                                    }}
                                  >
                                    <i class="mdi mdi-note btn-icon-prependd"></i>
                                    ADD CONDITION
                                  </button>
                                </Col>
                                )}
                              </Row>
                            </div>
                            <div>
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                keyField="_id"
                                bordered={true}
                                hover={true}
                                condensed={true}
                                noDataIndication={() => (
                                  <div>
                                    <LottieLoader
                                      loadLoop={false}
                                      autoplayLoop={true}
                                      source={lottieEmptyFile}
                                      Lheight={200}
                                      Lwidth={200}
                                      titleSmall={
                                        "Sorry! No prescribed conditions found :("
                                      }
                                      subTitleSmall={
                                        "We can't find any prescribed conditions."
                                      }
                                    />
                                  </div>
                                )}
                                data={this.props.patientConditions}
                                columns={[
                                  {
                                    dataField: "condition",
                                    text: "Name",
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                    formatter: (cell, row) => {
                                      if (!row.careprovider) {
                                        return <div>{row.condition} ()</div>;
                                      }
                                      return <div>{row.condition}</div>;
                                    },
                                  },
                                  {
                                    dataField: "description",
                                    text: "Description",
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                  {
                                    dataField: "description",
                                    text: "Created By",
                                    formatter: (cell, row) => {
                                      if (!row.careprovider) {
                                        return (
                                          <div className="text-center"> - </div>
                                        );
                                      }
                                      return (
                                        <div className="text-center">
                                          {row.careprovider.fname}{" "}
                                          {row.careprovider.lname}
                                        </div>
                                      );
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        textAlign: "center",
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                  {
                                    dataField: "updatedAt",
                                    text: "Last Updated",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      if (row.updatedAt) {
                                        return moment(row.updatedAt)
                                          .tz(moment.tz.guess())
                                          .format("MMMM DD, YYYY");
                                      } else {
                                        return "NONE";
                                      }
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                  {
                                    text: "Action",
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                    formatter: (cell, row) => {
                                      return (
                                        <div>
                                          <button
                                            type="button"
                                            className="btn btn-info ml-2 delete-note-click"
                                            onClick={() => {
                                              Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                  "Yes, delete it!",
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  this.deleteCondition(row._id);
                                                }
                                              });
                                            }}
                                          >
                                            <i class="mdi mdi-delete"></i>{" "}
                                            Delete
                                          </button>
                                        </div>
                                      );
                                    },
                                  },
                                ]}
                              />
                            </div>
                          </>
                        )}
                      {/* Prescribed Devices and Prescribed Testing Frequency */}
                      {!this.state.loadingCarePlan &&
                        !this.props.ongoingRequestCarePlan &&
                        this.props.profile.devices && (
                          <>
                            <div
                              className="care-plan-header-background"
                              style={{ marginTop: 25 }}
                            >
                              <Row>
                                <Col
                                  sm="12"
                                  className="center-label remove-table-padding"
                                >
                                  <h3
                                    className="text-center title-device"
                                    style={{ marginLeft: 25 }}
                                  >
                                    <i class="mdi mdi-mailbox"></i> Prescribed
                                    Devices and Prescribed Testing Frequency
                                  </h3>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                keyField="id"
                                bordered={true}
                                hover={true}
                                condensed={true}
                                data={this.props.devices}
                                noDataIndication={() => (
                                  <div>
                                    <LottieLoader
                                      loadLoop={false}
                                      autoplayLoop={true}
                                      source={lottieEmptyFile}
                                      Lheight={200}
                                      Lwidth={200}
                                      titleSmall={"Sorry! No device found :("}
                                      subTitleSmall={
                                        "We can't find any devices."
                                      }
                                    />
                                  </div>
                                )}
                                columns={[
                                  {
                                    dataField: "_id",
                                    text: "",
                                    formatter: this.fullname,
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        width: "100px",
                                        textAlign: "center",
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                  {
                                    dataField: "device.brand",
                                    text: "Device Brand",
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                  {
                                    dataField: "device.deviceType.description",
                                    text: "Device Type",
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                  {
                                    dataField: "device.brandModel",
                                    text: "Device Model",
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        textAlign: "center",
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                  {
                                    dataField: "serial",
                                    text: "Serial Number",
                                    formatter: (cell, row) => {
                                      return row.serial ? row.serial : "-";
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },

                                  {
                                    dataField: "performTest",
                                    text: "Perform Test",
                                    formatter: (cell, row) => {
                                      return (
                                        <div className="text-center">
                                          {row.performTest
                                            ? row.performTest
                                            : "-"}
                                        </div>
                                      );
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        textAlign: "center",
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                  {
                                    dataField: "testingFrequency",
                                    text: "Testing Frequency",
                                    formatter: (cell, row) => {
                                      return (
                                        <div className="text-center">
                                          {row.testingFrequency
                                            ? row.testingFrequency
                                            : "-"}
                                        </div>
                                      );
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        textAlign: "center",
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                  },
                                ]}
                              />
                            </div>
                          </>
                        )}

                      {/* Prescribed Expectations */}
                      {!this.state.loadingCarePlan &&
                        !this.props.ongoingRequestCarePlan &&
                        sortedArrayViewExpectations && 
                        <ExpectationsList
                          sortedArrayViewExpectations={
                            sortedArrayViewExpectations
                          }
                          userId={this.state.currentUserId}
                          onUpdate={this.updateIndiviualExpectation}
                        />
                      }
                      {this.state.currentUserId && (
                        <AddConditionsModal
                          modalAddConditions={this.state.modalAddConditions}
                          toggleAddCCMForm={this.toggleAddConditions}
                          updateConditions={this.updateConditions}
                          handleInputChangeUpdateCondition={
                            this.handleInputChangeUpdateCondition
                          }
                          userId={this.state.currentUserId}
                        />
                      )}
                      {this.state.currentUserId && (
                        <AddCCMModal
                          profile={this.props.profile}
                          profileOwner={this.props.profileOwner}
                          modelAddCCMForm={this.state.modelAddCCMForm}
                          toggleAddCCMForm={this.toggleAddCCMForm}
                          updateConditions={this.updateConditions}
                          handleInputChangeUpdateCondition={
                            this.handleInputChangeUpdateCondition
                          }
                          userId={this.state.currentUserId}
                        />  
                      )}
                      {this.state.currentUserId && (
                        <ViewCCMLogs
                          profile={this.props.profile}
                          profileOwner={this.props.profileOwner}
                          modelViewCCMFormLogs={this.state.modelViewCCMFormLogs}
                          toggleViewCCMFormLogs={this.toggleViewCCMFormLogs}
                          userId={this.state.currentUserId}
                          ccmSubmissinLogs={this.props.ccmSubmissinLogs}
                        />
                      )}
                      {this.state.currentUserId &&
                        this.props.latestSubmission.length > 0 &&
                        this.props.latestSubmission[0].submission && (
                          <ViewCCMDetails
                            profile={this.props.profile}
                            profileOwner={this.props.profileOwner}
                            modelViewCCMFormDetails={
                              this.state.modelViewCCMFormDetails
                            }
                            toggleViewCCMFormDetails={
                              this.toggleViewCCMFormDetails
                            }
                            userId={this.state.currentUserId}
                            latestSubmission={
                              this.props.latestSubmission[0].submission
                            }
                            submisionModel={this.props.latestSubmission[0]}
                          />
                        )}
                    </section>
                    <section
                      id="section-iconbox-8"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "8",
                      })}
                    >
                      <button
                        className="text-white float-right btn btn-info btn-icon-text create-note-click-cls"
                        onClick={this.toggleCcmQuestionsModal}
                        style={{ margin: 5 }}
                      >
                        <i className="mdi mdi-clipboard-plus"> </i>&nbsp;New CCM
                        Monthly Report
                      </button>

                      {this.props.ccmAnswers &&
                        _.size(this.props.ccmAnswers) > 0 && (
                          <button
                            className="text-white float-right btn btn-info btn-icon-text on-boarding-ccm-note-click-cls"
                            onClick={this.toggleOldCcmAnswerModal}
                            style={{ margin: 5 }}
                          >
                            <i className="mdi mdi-eye"> </i>&nbsp;Archived CCM
                            Monthly Reports
                          </button>
                        )}
                      <CCMPatientAnswerList
                        currentUserId={this.props.profile._id}
                        toggleCcmAnswersModal={this.toggleCcmAnswersModal}
                        mQuestionsList={this.props.questions}
                      />
                      {this.props.userRole != undefined &&
                        this.props.userRole != "patient" && (
                          <AddConditionsModal
                            modalAddConditions={this.state.modalAddConditions}
                            toggleAddConditions={this.toggleAddConditions}
                            updateConditions={this.updateConditions}
                            handleInputChangeUpdateCondition={
                              this.handleInputChangeUpdateCondition
                            }
                          />
                        )}
                      {this.props.userRole != undefined &&
                        this.props.userRole != "patient" && (
                          <AddCCMQuestionnaire
                            ccmQuestionsModal={this.state.ccmQuestionsModal}
                            toggleCcmQuestionsModal={
                              this.toggleCcmQuestionsModal
                            }
                            mQuestionsList={this.props.questions}
                          />
                        )}
                      {this.props.userRole != undefined &&
                        this.props.userRole != "patient" && (
                          <CCMPatientAnswerListOld
                            oldCCMAnswerModal={this.state.oldCCMAnswerModal}
                            toggleOldCcmAnswerModal={
                              this.toggleOldCcmAnswerModal
                            }
                            mQuestionsList={this.props.questions}
                          />
                        )}
                    </section>
                    <section
                      id="section-iconbox-9"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "9",
                      })}
                    >
                      <ThirtyDaysSummary
                        currentUserId={this.props.profile._id}
                        fname={this.props.profile.fname}
                        lname={this.props.profile.lname}
                      />
                    </section>
                    <section
                      id="section-iconbox-10"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "10",
                      })}
                    >
                      <div className={`text-center col-md-12 mx-auto`}>
                        <Row>
                          <Col md={3}>
                            <FormGroup className="remove-bottom-margin">
                              <Label for="exampleEmail">Start Date</Label>
                              <Input
                                className="input-date-filter"
                                type="date"
                                name="startDateForQuestionnaire"
                                max={moment().format("YYYY-MM-DD")}
                                onChange={this.handleInputQuestionnaireChange}
                                value={this.state.startDateForQuestionnaire}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="remove-bottom-margin">
                              <Label for="examplePassword">End Date</Label>
                              <Input
                                className="input-date-filter"
                                type="date"
                                name="endDateForQuestionnaire"
                                max={moment().format("YYYY-MM-DD")}
                                onChange={this.handleInputQuestionnaireChange}
                                value={this.state.endDateForQuestionnaire}
                              />
                              <Label
                                style={{
                                  color: "red",
                                  textAlign: "left",
                                  padding: 5,
                                }}
                              >
                                {this.state.endDateError}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="remove-bottom-margin">
                              <Label for="exampleEmail">
                                Questionnaire Filter
                              </Label>
                              {questionnaireNameList && (
                                <select
                                  className="filter-Questionnaire"
                                  name="questionnaireName"
                                  onChange={this.handleQuestionnaireChange}
                                >
                                  <option value="-1">
                                    {" "}
                                    Select Questionnaire{" "}
                                  </option>
                                  {questionnaireNameList &&
                                    questionnaireNameList.map((model) => (
                                      <option value={model.questionnaire._id}>
                                        {model.questionnaireName}
                                      </option>
                                    ))}
                                </select>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="remove-bottom-margin">
                              <Label for="exampleEmail">Frequency Filter</Label>
                              {questionnaireRepetedList && (
                                <select
                                  className="filter-Questionnaire"
                                  name="questionnaireRepeatPer"
                                  onChange={this.handleQuestionnaireChange}
                                >
                                  <option value="-1"> Select Frequency </option>
                                  {questionnaireRepetedList &&
                                    questionnaireRepetedList.map((model) => (
                                      <option
                                        value={model.questionnaireRepeatPer}
                                      >
                                        {model.questionnaireRepeatPer.toUpperCase()}
                                      </option>
                                    ))}
                                </select>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <QuestionnaireList
                        myQuestionnaire={this.props.myQuestionnaire}
                        userDetails={this.props.questionnarieUserDetails}
                        loading={this.props.ongoingRequestQuesionnaire}
                      />
                    </section>
                    <section
                      id="section-iconbox-5"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "6",
                      })}
                    >
                      <UpdatePrescribe profile={this.props.profile} />
                    </section>
                    <section
                      id="section-iconbox-4"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "11",
                      })}
                    >
                      {this.props &&
                        this.props.profile &&
                        this.props.profile._id && (
                          <>
                            <Alert color="success">
                              Here, by default we are viewing past 6 months of
                              notifications and messages data. You can use the
                              filters for retrieving specific data.
                            </Alert>
                            <Reports
                              patientId={
                                (this.props.match &&
                                  this.props.match.params?.id) ??
                                this.props.profile._id
                              }
                              patientProfile={true}
                            ></Reports>
                          </>
                        )}
                    </section>
                    {this.state.activeTab === "12" && (
                      <>
                        <section
                          id="section-iconbox-4"
                          className={classnames({
                            contentcurrent: this.state.activeTab === "12",
                          })}
                        >
                          <ChatTextMessage
                            patientId={this.props.profile._id}
                            allChatMessages={this.props.allChatMessages}
                            isLoadingChatList={this.props.isLoadingChatList}
                            userId={this.props.userId}
                          />
                        </section>
                      </>
                    )}
                  </div>
                  {/* /content */}
                </div>
                {/* /tabs */}
              </section>
            </div>
          </div>
          <SweetAlert
            error
            title={this.props.genericErrorMessage.message ?? ""}
            show={this.props.isGenericError}
            onConfirm={() => {
              this.props.dispatch({
                type: "GENERIC_ERROR",
              });
            }}
            confirmBtnBsStyle="danger"
            timeout={1000}
          >
            Error Code: {this.props.genericErrorMessage.error}
          </SweetAlert>
        </div>
      );
    }
    return (
      <ReactLoading type="spin" className="text-center mx-auto" color="blue" />
    );
  }
}

const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    usersOfferedAccessOrganization,
    timerStarts,
    pmRecords,
    usersViolations,
    readings,
    dexcomRaw,
    dexcomGlucoseData,
    notes,
    patientConditions,
    patientExpectations,
    questions,
    ccmAnswers,
    bodytraceReadings,
    myQuestionnaire,
    questionnaireName,
    questionnaireRepeted,
    patientCarePlanExpectations,
    patientWeightExpectations,
    notesPatientDetails,
    latestReadingUser,
    ccmTotalDuration,
    readingFilePath,
    rpmTotalDuration,
    ongoingbothReadingRequest,
    ongoingUserViolationRequest,
    ongoingPatientProfileRequest,
    ongoingPdfExcelRequest,
    questionnarieUserDetails,
    readingFrequency,
    ongoingTestingFrequencyRequest,
    devices,
    totalReadingFrequency,
    ongoingRequestQuesionnaire,
    ongoingRequestCarePlan,
    ccmSubmissinLogs,
    latestSubmission,
    isBloodPressureReading,
    isLoadingChatList,
    allChatMessages,
    usersViolationsRecordsCount
  } = careprovider;
  
  const { readingSubTypes, readingType } = auth;
  const userId = user.userDetails._id;
  const profile = user.profile;
  const profileOwner = user.profileOwner;
  const userDetails = user;
  const userRole = user.userDetails.role;
  const staffRole = user.userDetails.staffRole;
  const isOtherRole = user.userDetails.isOtherRole;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    readingType,
    usersOfferedAccessOrganization,
    userId,
    usersViolations,
    pmRecords,
    timerStarts,
    profile,
    profileOwner,
    readings,
    dexcomRaw,
    dexcomGlucoseData,
    notes,
    patientConditions,
    patientExpectations,
    questions,
    userDetails,
    userRole,
    ccmAnswers,
    bodytraceReadings,
    myQuestionnaire,
    questionnaireName,
    questionnaireRepeted,
    patientCarePlanExpectations,
    patientWeightExpectations,
    notesPatientDetails,
    latestReadingUser,
    ccmTotalDuration,
    readingFilePath,
    rpmTotalDuration,
    ongoingbothReadingRequest,
    ongoingUserViolationRequest,
    ongoingPatientProfileRequest,
    ongoingPdfExcelRequest,
    questionnarieUserDetails,
    readingFrequency,
    ongoingTestingFrequencyRequest,
    devices,
    totalReadingFrequency,
    ongoingRequestQuesionnaire,
    ongoingRequestCarePlan,
    ccmSubmissinLogs,
    latestSubmission,
    isBloodPressureReading,
    isLoadingChatList,
    allChatMessages,
    staffRole,
    isOtherRole,
    usersViolationsRecordsCount
  };
};

export default connect(mapStateToProps)(UserDashboard);
